import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from 'interface/common';
import { UserNameFormat } from 'utils/nameFormat';
import { Alert, Button, Card, Col, Divider, Flex, Image, Row, Space, Typography } from 'antd';
import { iAuthImage, iAuthLink, iCardText, iChildren, iEmail, iFormHeading, iLogoContent } from 'interface/Auth';
import { authImages, cardAlign, cardBlur, cardBlurContent, cardBlurContentButton, extraInfo } from 'utils/styles';
import { cardStyle, cardTextStyle, dividerColor, logoStyle, resetText } from 'utils/styles';
import { ServerVars } from 'utils/ServerVars';

/** These below are used as component in Auth pages
 * @AuthenticationForm - Auth forms
 * @AuthLogin - Auth forms only in login page
 * @FormHeading - Heading layout
 * @AuthImage - Background image in Auth screens
 * @AuthLink - Auth links
 * @Text - Extra info in a blurry card
 * @excludedPaths Ignores pageDetails in Admin Layout */

const { Text, Title } = Typography;

const appName = PROJECT_NAME;

export const AuthenticationForm: FC<iChildren> = ({ children }: iChildren) => (
  <Card size="small" style={cardAlign}>
    {children}
  </Card>
);

export const AuthLogin: FC<iChildren> = ({ children }: iChildren) => (
  <>
    <ImageB />
    <SignupLink />
    <AuthenticationForm>
      <LoginHeading />
      {children}
    </AuthenticationForm>
    <TextLogin />
  </>
);

const FormHeading: FC<iFormHeading> = ({ title, description }) => (
  <Space direction="vertical">
    <Title level={3}> {title} </Title>
    <Text> {description} </Text> <Text />
  </Space>
);

export const LoginHeading = () => <FormHeading title="Login into your account" description="Hello there! Please input your email to proceed." />;

export const SignupHeading = () => <FormHeading title={`Get Started With ${appName}`} description="Sign up to start your journey." />;

export const ForgotPasswordHeading = () => (
  <FormHeading
    title="Forgot password?"
    description="Forgot your password? Don't worry! We've got you covered. Please enter your email address below, and we will send you a secure link to reset your password."
  />
);

export const ChangePasswordHeading = () => (
  <FormHeading
    title="Change password"
    description={`Hello, ${UserNameFormat()} To ensure the security of your account, please proceed to set your password.`}
  />
);

export const CreatePassword = () => <FormHeading title="Create user password" description="You can create a new user password for your Tenant" />;

export const PasswordSentHeading: FC<iEmail> = ({ email }) => {
  const resetInfo = (
    <Space direction="vertical" style={resetText}>
      <Text>
        We will send you a password reset link if the email ID provided <Text strong> {email} </Text> matches the one we have on records.
      </Text>
      <Text> Kindly check your email for the next steps. If the email address is incorrect, click here to make the necessary changes. </Text>
      <Text> If you have not received a Password reset link in the next few minutes: </Text>
      <ol>
        <li>
          <Text>
            Check your Junk/Spam folder for an Email from <Text strong> noreply@nanoheal.com </Text>.
          </Text>
        </li>
        <li>
          <Text> Make sure that the Email ID you entered matches the one used while registering. </Text>
        </li>
      </ol>
    </Space>
  );
  return <FormHeading title="Password eeset email sent!" description={resetInfo} />;
};

export const FormFooter = () => (
  <Alert
    showIcon
    type="info"
    message="Create a password with at least 8 characters, including a mix of uppercase, lowercase, numbers, and special characters. Avoid common words and personal information. Keep your account safe by choosing a unique password"
    style={extraInfo}
  />
);

export const [ImageA, ImageB, ImageC, ImageD] = ['1', '2', '3', '4'].map(img =>
  (
    ({ image }: iAuthImage) =>
    () =>
      <div style={{ ...authImages, backgroundImage: `url(${image})` }} />
  )({ image: `branding/loginImage_${img}.svg` }),
);

const LogoContent: FC<iLogoContent> = ({ img, divider }) => (
  <Space direction="horizontal">
    <Image src={img} alt={`${appName} Logo`} preview={false} />
    <Text strong> {appName} </Text>
    {divider && <Divider type="vertical" style={dividerColor} />}
  </Space>
);

export const BrandLogoMain = () => (
  <Link to="/" style={logoStyle}>
    <LogoContent img="branding/headerLogo.svg" divider />
  </Link>
);

export const BrandLogo = () => (
  <Card size="small" style={cardStyle}>
    <LogoContent img="branding/headerLogo.svg" />
  </Card>
);

const AuthLink: FC<iAuthLink> = ({ text, link }) => {
  const showSignupLocaly = ['localhost', 'auth', 'sso'].includes(window.location.host.split(/[.:]/)[0]);

  return (
    <Flex gap="middle" justify="space-between">
      <BrandLogo />
      {showSignupLocaly && (
        <Card size="small" style={cardStyle}>
          <Text strong>
            {text} &nbsp; <Link to={link}> {link === '/login' ? 'Login' : 'Sign up'} </Link>
          </Text>
        </Card>
      )}
    </Flex>
  );
};

export const SignupLink = () => {
  if (!ServerVars.ALLOW_signup) {
    return <AuthLink text="Signup is not allowed. Please contact your administrator." link="/login" />;
  }
  return <AuthLink text="Don't have an account?" link="/signup" />;
};
export const LoginLink = () => <AuthLink text="Already have an account?" link="/login" />;

export const AuthText: FC<iCardText> = ({ text }) => (
  <Row style={cardBlur}>
    <Col span={1} />
    <Col span={23}>
      <Flex align="flex-end" justify="flex-end">
        <Card size="small" style={cardBlurContent}>
          <Space direction="vertical">
            <Button shape="round" size="large" style={cardBlurContentButton} icon={<Image src="branding/loginThumbsupImage.svg" preview={false} />}>
              <Text> One portal to manage them all </Text>
            </Button>
            <Text style={cardTextStyle}> {text.replace(/name/g, appName)} </Text>
          </Space>
        </Card>
      </Flex>
    </Col>
  </Row>
);

const { login, signup, forgot, change, find, password } = {
  login: `Embark on a digital odyssey with name, harnessing the power to seamlessly navigate clouds, clusters, and deployments alike. With just one click, our portal becomes your gateway to unified control, ensuring your IT infrastructure is as harmonious as a well-orchestrated symphony.`,
  signup: `Begin your digital journey with name. Join our community in a few clicks. Navigate clouds, clusters, and deployments seamlessly. name ensures harmony in your IT infrastructure.`,
  forgot: `Unable to remember your password? No problem! Easily regain access with name. Just enter your email, and we'll guide you through resetting it.`,
  change: `Secure your digital journey with name's password management. Take control of your security. Ensure a smooth, secure experience through clouds, clusters, and deployments.`,
  find: `Navigate your IT infrastructure effortlessly with name's search. Locate pages and resources quickly. Stay efficient on your digital journey.`,
  password: `Craft a strong password with name to secure your account. Our guidelines will help you create a unique and resilient password, ensuring your digital assets are protected from unauthorized access.`,
};

export const TextLogin = () => <AuthText text={login} />;
export const TextSignup = () => <AuthText text={signup} />;
export const TextForgot = () => <AuthText text={forgot} />;
export const TextChange = () => <AuthText text={change} />;
export const TextFind = () => <AuthText text={find} />;
export const TextPassword = () => <AuthText text={password} />;

export const excludedPaths = [
  '/',
  '/projects',
  '/clusters',
  '/charts',
  '/users',
  '/audit-logs',
  '/integrations',
  '/tariffs',
  '/new-intro',
  '/new-from-template',
];
