import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { Link } from 'react-router-dom';
import { iRegionModel } from 'shared/deployment';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { Alert, Col, Space, Typography } from 'antd';
import { clusterHeight, spaceWidth } from 'utils/styles';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { ChartDashboardMap } from 'components/ChartRenderer/ChartDashboard';

const { Text } = Typography;

export interface iClusterOpenCostPriceProps {
  region: iRegionModel;
}

export const ClusterOpenCostPrice = (props: iClusterOpenCostPriceProps) => {
  const region: iRegionModel = props.region;
  const stats_opencost = [
    {
      id: 0,
      lg: 24,
      span: `24`,
      name: 'Price from OpenCost',
      vizState: {
        chartType: 'area',
        query: {
          limit: 500,
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['hour'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['opencost'] },
            { member: 'CostHistory.regionId', operator: 'equals', values: [`${region.id}`] },
          ],
          dimensions: ['Deployments.id', 'Deployments.name'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 30 days' }],
          measures: ['CostHistory.sum'],
          order: { 'CostHistory.sum': 'desc' },
        },
      },
    },
  ];

  const alertMessage = (
    <Text>
      OpenCost is a tool for tracking and optimizing cloud costs. It will work only if you have OpenCost agent installed on your Cluster. You can
      check installation instructions on the
      <Text strong>
        <TipTop tip="Click here to view Integrations">
          <Link to={`/clusters/${region.id}/integrations/1`}> OpenCost Integration Page </Link>
        </TipTop>
      </Text>
    </Text>
  );

  return (
    <>
      <ChartDashboardMap
        filters={[
          {
            type: 'dateRange',
            value: `Last 30 day`,
            options: [
              { label: '7 days', value: 'Last 7 day' },
              { label: '14 days', value: 'Last 14 day' },
              { label: '30 days', value: 'Last 30 day' },
              { label: '60 days', value: 'Last 60 day' },
              { label: '90 days', value: 'Last 90 day' },
            ],
          },
          {
            type: 'granularity',
            value: `day`,
            options: [
              { label: 'Day', value: 'day' },
              { label: 'Week', value: 'week' },
            ],
          },
        ]}
        stats={stats_opencost}
      />
      <Alert showIcon type="info" message={alertMessage} />
    </>
  );
};
