import gql from 'graphql-tag';
import { ReactElement, useEffect, useState } from 'react';
import { projectService } from 'services/project.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { createGitHubRepoForProject, getGitHubAllRepos, getGitHubToken, isValidGithubApiToken } from 'services/githubApi';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { ProjectGitBtn_Clone, ProjectGitBtn_Pull, ProjectGitBtn_push, projectGitPull } from './GitButtons';
import { Button, notification, Skeleton, Alert, Input, Space, Select, Form, Radio, Tag, Typography, Popover, Divider } from 'antd';
import { GithubOutlined, KeyOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { spaceWidth, buttonBorder, divWidth } from 'utils/styles';
import { iDeployment } from 'shared/deployment';
import { TipRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { GitLogs } from './gitLogs';
import { GitHelpInfoAlert } from './GitHelpInfoAlert';
import { ProjectGitConfigurationHelp } from './ProjectGitConfigurationHelp';
import RadioButton from 'antd/es/radio/radioButton';
import { useHistory } from 'react-router-dom';

interface iProjectGitSettings {
  deployment: iDeployment;
  onSave?: () => void;
}

const { Text, Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;

export const Btn_CreateGitHubRepoIfNotExist = (props: { repoIsLinked: boolean; deployment: iDeployment }) => {
  const history = useHistory();
  const isValidGithubToken = isValidGithubApiToken();

  const saveAndPull = async () => {
    const { clone_url, default_branch } = await createGitHubRepoForProject(props.deployment.ProjectModel);
    const { error } = await projectService.saveProjectGitConfig(props.deployment.projectId, {
      gitUrl: clone_url,
      gitBranch: default_branch,
      gitAuthType: 'github-oauth',
      gitToken: getGitHubToken(),
    });
    await projectGitPull(props.deployment.projectId, (isOk: boolean) => {
      if (isOk) {
        // history.push(`/app/${props.deployment.id}/configuration/services`);
        window.location.reload();
      } else {
        notification.error({ message: 'Error pulling changes from git' });
      }
    });

    if (error) {
      notification.error({ message: `Error: ${error}` });
    }

    notification.success({ message: 'Ready' });
  };

  return (
    <Button
      type={props.repoIsLinked ? 'default' : 'primary'}
      data-cy="git-repo-auth"
      icon={<GithubOutlined />}
      style={buttonBorder}
      onClick={() => {
        if (!isValidGithubToken) {
          let gitOAuthLink = getGitOAuthLink(props.deployment.id);
          window.location.replace(gitOAuthLink);
          return;
        }

        saveAndPull();
      }}
    >
      Create a GitHub repo
    </Button>
  );
};

function getGitOAuthLink(deploymentId: number) {
  let gitOAuthLink = `/api/github/oauth/repo-authorize?params=${encodeURIComponent(`gitAutoCreate-${deploymentId}`)}`;

  if (window.location.hostname === 'localhost') {
    gitOAuthLink = `http://localhost:4001${gitOAuthLink}`;
  }
  return gitOAuthLink;
}

const BusyAlertUI = () => {
  return <Alert showIcon type="warning" message="Project is locked by another user. Please wait until all other users finish the work with git" />;
};

const GitIsNotReadyUI = (props: { projectId: number }) => (
  <Alert
    showIcon
    type="error"
    key={'gitInitialized'}
    message="Git is not Initialized"
    description={
      <Space direction="vertical">
        <Text>
          Set up a git repository connection to your template and click to the <Text strong> "git pull" </Text> button.
        </Text>
        <Text> Templates store their configuration in git. This allows you to use version history. </Text>
      </Space>
    }
    action={
      <Button type="primary" onClick={() => projectGitPull(props.projectId)}>
        Pull files from GIT
      </Button>
    }
  />
);

export const LinkedUI = (props: { gitUrl: string; gitBranch: string; onReLink: () => void }) => {
  const { gitUrl, gitBranch } = props;
  return (
    <Space direction="horizontal">
      <Text> Linked to branch: </Text>
      <TipRight tip={`Click her if you want to link this service to your branch ${gitBranch}`}>
        <Text strong>
          <Tag color="green">
            <a href={`http://${gitUrl}`} target="_blank">
              {gitBranch}
            </a>
          </Tag>
        </Text>
      </TipRight>
      <Button type="dashed" size="small" onClick={() => props.onReLink()}>
        Unlink
      </Button>
    </Space>
  );
};

export const ProjectGitSettings = (props: iProjectGitSettings): ReactElement => {
  const projectId = props.deployment.projectId;

  const [dstBranch, setDstBranch] = useState(null);
  const [gitReposArray, setGitRepos] = useState([]);
  const [gitReposBranches, setGitRepoBranches] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [selectedGitHubRepo, setSelectedGitHubRepo] = useState(null);
  const [reLink, setReLink] = useState(false);

  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ProjectGitController_checkGitLock($projectId: Int!) {
        ProjectGitController_checkGitLock(projectId: $projectId)
        ProjectController_getProject(projectId: $projectId) {
          gitInitialized
        }
        ProjectGitController_getGitConfiguration(projectId: $projectId) {
          gitToken
          gitUrl
          gitIntegrationType
          gitBranch
          gitAuthType
        }
      }
    `,
    { variables: { projectId: projectId }, pollInterval: 10000 },
  );
  startPolling(10000);

  const git = data?.ProjectGitController_getGitConfiguration;
  let selectedAuthType = authType || git?.gitAuthType || 'github-oauth';

  const readyForGitOperations = git && git.gitUrl && git.gitBranch;
  const readyForGitPushOperations = git && git.gitUrl && git.gitBranch && git.gitToken;
  const repoIsLinked = !reLink && git?.gitUrl && git?.gitBranch;
  const gitInitialized = data?.ProjectController_getProject?.gitInitialized;
  const isValidGithubToken = isValidGithubApiToken();

  useEffect(() => {
    git ? setDstBranch(git.gitBranch) : null;
    isValidGithubToken ? (async () => setGitRepos(await getGitHubAllRepos()))() : null;
    return stopPolling;
  }, [git]);

  const handleSubmit = async (e: { gitAuthType: string; gitUrl: string; gitBranch: any; gitToken: string }) => {
    if (e.gitAuthType === 'github-oauth') {
      if (!selectedGitHubRepo) {
        notification.error({ message: 'Please select repository' });
        return;
      }
      e.gitUrl = String(selectedGitHubRepo?.clone_url).replace('http://', '').replace('https://', '');
      e.gitBranch = dstBranch;
      e.gitToken = getGitHubToken();
    } else {
      setDstBranch(e.gitBranch);
    }
    e.gitUrl = String(e.gitUrl).replace('http://', '').replace('https://', '');

    const res = await projectService.saveProjectGitConfig(projectId, e);
    refetch();
    if (res.error) {
      notification.error({ message: `Error: ${res.error}` });
    } else {
      if (props.onSave) {
        props.onSave();
      }
      notification.success({ message: 'Ready' });
    }

    if (readyForGitOperations && gitInitialized === false) {
      await projectGitPull(projectId);
    }
  };

  if (!git) {
    return <Skeleton active={true} loading={true} />;
  }

  let gitOAuthLink = `/api/github/oauth/repo-authorize?params=${encodeURIComponent(`gitconfig-${props.deployment.id}`)}`;
  if (window.location.hostname === 'localhost') {
    gitOAuthLink = `http://localhost:4001${gitOAuthLink}`;
  }

  const gitAuth = () => {
    const formFields = [
      {
        key: 'gitUrl',
        name: 'gitUrl',
        label: 'Git repository URL',
        placeholder: "Enter URL (without 'https://') here",
        type: 'text',
        addon: (
          <Popover
            placement="topLeft"
            title={`Your git repository url should be in the format "gitlab.com/username/repo or "github.com/username/project.git"`}
          >
            <QuestionCircleTwoTone twoToneColor="#00A58E" />
          </Popover>
        ),
      },
      {
        key: 'gitToken',
        name: 'gitToken',
        label: 'Access Token',
        placeholder: 'Enter Access Token here',
        type: 'password',
        addon: <ProjectGitConfigurationHelp btnText={<QuestionCircleTwoTone twoToneColor="#00A58E" />} />,
      },
      {
        key: 'gitBranch',
        name: 'gitBranch',
        label: 'Branch',
        placeholder: 'main',
        type: 'text',
        addon: (
          <Popover placement="topLeft" title="Often it is `main` or `master` branch">
            <QuestionCircleTwoTone twoToneColor="#00A58E" />
          </Popover>
        ),
      },
    ];

    return (
      !repoIsLinked && (
        <>
          <Space size="large" direction="horizontal" style={spaceWidth}>
            <Btn_CreateGitHubRepoIfNotExist deployment={props.deployment} repoIsLinked={repoIsLinked} />
            <Text> OR </Text>
            <RadioButton onChange={() => setAuthType(`token`)}>Use existing repository</RadioButton>
          </Space>

          {selectedAuthType === `token` && (
            <Form onFinish={handleSubmit} initialValues={git}>
              <br />
              <br />

              {formFields.map(({ key, name, label, placeholder, type, addon }) => (
                <Item key={key} name={name} label={<Text strong> {label} </Text>} rules={[{ required: true }]}>
                  <Input placeholder={placeholder} type={type} style={divWidth} addonAfter={addon} />
                </Item>
              ))}
              <BottomButtons extra={<ProjectGitBtn_Clone disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />}>
                <Button type="primary" htmlType="submit" style={buttonBorder}>
                  Save
                </Button>
                <ProjectGitBtn_push disabled={!readyForGitPushOperations} projectId={projectId} onClick={refetch} dstBranch={dstBranch} />
                <ProjectGitBtn_Pull disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
              </BottomButtons>
            </Form>
          )}
        </>
      )
    );
  };

  const GitOperationsUI = (
    <>
      <LinkedUI
        gitUrl={git.gitUrl}
        gitBranch={git.gitBranch}
        onReLink={() => {
          setReLink(true);
        }}
      />

      <BottomButtons extra={<ProjectGitBtn_Clone disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />}>
        <ProjectGitBtn_push disabled={!readyForGitPushOperations} projectId={projectId} onClick={refetch} dstBranch={dstBranch} />
        <ProjectGitBtn_Pull disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
      </BottomButtons>
    </>
  );

  const showBusy = data && data.ProjectGitController_checkGitLockl;
  const isNotReady = readyForGitOperations && data?.ProjectController_getProject && gitInitialized === false;
  return (
    <Space direction="vertical" style={spaceWidth}>
      {!gitInitialized && (
        <>
          <GitHelpInfoAlert /> <Text />
        </>
      )}
      {error && <Alert type="warning" showIcon key={'error'} message="Error" description={JSON.stringify(error)} />}
      {showBusy && <BusyAlertUI />}
      {isNotReady && <GitIsNotReadyUI projectId={projectId} />}

      {repoIsLinked && GitOperationsUI}
      {gitAuth()}

      {gitInitialized && (
        <>
          <Divider plain>Git history</Divider>
          <GitLogs project={props.deployment.ProjectModel} noPush={true} />
        </>
      )}
    </Space>
  );
};
