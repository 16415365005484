import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setPageWidgets } from 'services/actions';
import { DeploymentSelectionComponent } from './DeploymentSelectionComponent/DeploymentSelectionComponent';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iProjectDeploymentContext } from './ProjectDeploymentContext';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { Button, Space, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { buttonSize, cardTextStyle } from 'utils/styles';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';
import { GitStatusComponent } from './GitStatusComponent/GitStatusComponent';

const { Title, Text } = Typography;

export const ProjectWidgetsRow = (props: { project: iProjectModel; deployment?: iDeployment; setDeploymentContext: any }) => {
  const project = props.project;
  const [services, error, loading] = useApiQuery(() => projectService.getProjectServiceList(project.id));

  const hasServices = !loading && !error && project.gitInitialized ? (services?.length === 0 ? false : true) : null;

  useEffect(() => {
    if (project) {
      const row = newServices();
      setPageWidgets(row);
    }
    return () => {
      setPageWidgets(null);
    };
  }, [project, hasServices]);

  const newServices = () => (
    <Space>
      {project.gitInitialized === true && props.deployment?.isReady && (
        <GitStatusComponent projectId={project.id} deploymentId={props?.deployment?.id} />
      )}
      {hasServices === false && (
        <TipTop
          tip={
            <Space direction="vertical">
              <Title level={5} style={cardTextStyle}>
                No services in this project
              </Title>
              <Text style={cardTextStyle}> This project does not have any services. Please create at least one service. </Text>
            </Space>
          }
        >
          <Link to={`/app/${props.deployment.id}/configuration/new-service`}>
            <Button danger icon={<ExclamationCircleFilled />} style={buttonSize}>
              Add Service
            </Button>
          </Link>
        </TipTop>
      )}

      {hasServices === true && project.gitInitialized === true && (
        <DeploymentSelectionComponent
          projectId={project.id}
          deployment={props.deployment}
          onSelect={async (deployment: iDeployment) => {
            const newContextValue: iProjectDeploymentContext = { deployment };
            props.setDeploymentContext(newContextValue);
          }}
        />
      )}
      <DeployButton title="Deploy template" deployment={props.deployment} />
    </Space>
  );
  return null;
};
