import MetacontrollerLogs from '../monitoring/metacontroller';
import K8sEvents from '../monitoring/k8sEvents';
import { useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { DeploymentNamespace } from './General/DeploymentNamespace';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { orangeColor, redColor, topMargined } from 'utils/styles';
import { useUser } from 'utils/common';
import { Skeleton, Tabs } from 'antd';
import { HomeOutlined, WarningOutlined } from '@ant-design/icons';
import { YamlTab } from './General/YamlTab';
import { RoutesTab } from './General/RoutesTab';
import { PodsLogs } from '../PodsLogs/PodsLogs';
import { DeploymentPvcList } from '../monitoring/DeploymentPvcList';
import { DeploymentWebsshConsole } from '../DeploymentWebssh/DeploymentWebssh';
import { DeploymentTitle } from './DeploymentStatusPage';

const { TabPane } = Tabs;

export function DeploymentLogsPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();
  const user = useUser();
  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  let tabNameType = String(tabName || '').split('_')[0] || `namespace`;

  if (!tabNameType) history.push(`/app/${id}/logs/namespace`);
  if (error) {
    return (
      <Skeleton active={false} loading={false}>
        {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (!user || loading || !deployment || !deployment.isReady) return <Skeleton active={true} loading={true} />;
  if (!deployment.isReady) history.push(`/app/${deployment.id}/logs/namespace`);

  const specsErrors = deployment?.specsErrors;
  const specsWarns = deployment?.specsWarns;

  const YamlIcons = specsErrors ? (
    <WarningOutlined twoToneColor={'#FF00000'} style={redColor} />
  ) : specsWarns ? (
    <WarningOutlined twoToneColor={'orange'} style={orangeColor} />
  ) : null;

  const diagnosticsTabs = [
    {
      permission: UserPermissions.DeploymentLogs,
      tab: { key: 'pods', label: 'Pods logs', component: <PodsLogs deployment={deployment} tabName={tabName} /> },
    },
    {
      permission: UserPermissions.DeploymentSpecs,
      tab: { key: 'namespace', label: 'Namespace', component: <DeploymentNamespace deployment={deployment} /> },
    },
    {
      permission: UserPermissions.DeploymentLogs,
      tab: { key: 'sync', label: 'Sync logs', component: <MetacontrollerLogs deployment={deployment} /> },
    },
    {
      permission: UserPermissions.DeploymentLogs,
      tab: { key: 'events', label: 'Events', component: <K8sEvents deployment={deployment} /> },
    },
    {
      permission: UserPermissions.DeploymentSpecs,
      tab: { key: 'yaml', label: <> {YamlIcons} Yaml </>, component: <YamlTab deployment={deployment} /> },
    },
    {
      permission: UserPermissions.DeploymentSpecs,
      tab: { key: 'routes', label: 'Routes', component: <RoutesTab deployment={deployment} /> },
    },
    {
      permission: UserPermissions.DeploymentSpecs,
      tab: { key: 'volumes', label: 'Volumes', component: <DeploymentPvcList deployment={deployment} /> },
    },
    {
      permission: UserPermissions.DeploymentWebSSH,
      tab: { key: 'console', label: 'Console', component: <DeploymentWebsshConsole deployment={deployment} /> },
    },
  ];

  return (
    <>
      <TitleUIRow title={<DeploymentTitle deployment={deployment} />} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Tabs defaultActiveKey={tabName || `pods`} style={topMargined} onChange={(activeKey: string) => history.push(`/app/${id}/logs/${activeKey}`)}>
        {diagnosticsTabs.map(
          ({ permission, tab }) =>
            userHasPermission(user, permission) && (
              <TabPane tab={tab.label} key={tab.key}>
                {tab.component}
              </TabPane>
            ),
        )}
      </Tabs>
    </>
  );
}
