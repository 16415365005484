import InfoUpdate from '../Deployments/OverView/InfoUpdateComponent';

const AuditDiff = (props: any) => {
  const data = [];
  const diff = props.diff;
  console.log(diff);
  if (!diff) {
    data.push(<div>...</div>);
  } else {
    Object.keys(diff || []).map(key => {
      if (key == 'env' || (key == 'serviceVars' && Object.keys(diff[key]).length)) {
        Object.keys(diff[key]).map(elem => {
          data.push(
            <div>
              <InfoUpdate
                keyElement={elem}
                valueElement={JSON.stringify(diff[key][elem]['new'])}
                valueElementOld={JSON.stringify(diff[key][elem]['old'])}
              />
              <br />
            </div>,
          );
        });
      } else {
        data.push(
          <div>
            <InfoUpdate
              keyElement={key}
              valueElement={JSON.stringify(diff[key]['new'] ? diff[key]['new'] : diff[key])}
              valueElementOld={diff[key]['old'] ? diff[key]['old'] : ''}
            />
            <br />
          </div>,
        );
      }
    });
  }
  return <div>{data}</div>;
};
export default AuditDiff;
