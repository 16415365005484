import { ChartDashboardMap } from 'components/ChartRenderer/ChartDashboard';
import { iDeployment } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';

interface iOneServiceMetricsProps {
  service: iCloudProjectServiceYamlSpecs;
  serviceName: string;
  deployment: iDeployment;
}

export const OneServiceMetrics = (props: iOneServiceMetricsProps) => {
  const id = props.deployment.id;

  const serviceNameFilter = { member: 'Monitoring.serviceName', operator: 'equals', values: [`${props.serviceName}`] };
  const stats = [
    {
      id: 0,
      span: `24`,
      name: 'Response time',
      vizState: {
        query: {
          measures: ['Monitoring.timeResponse'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 30 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
    {
      id: 0,
      span: `24`,
      name: 'Availability for deployments',
      vizState: {
        query: {
          measures: ['Monitoring.okRate'],
          timeDimensions: [{ dimension: 'Monitoring.createdAt', granularity: 'minute', dateRange: 'Last 30 minute' }],
          dimensions: ['Monitoring.serviceName'],
          filters: [serviceNameFilter, { member: 'Monitoring.deploymentId', operator: 'equals', values: [`${id}`] }],
        },
        chartType: 'line',
      },
    },
  ];

  return <ChartDashboardMap stats={stats} />;
};
