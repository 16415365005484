import _ from 'lodash';
import * as yaml from 'js-yaml';
import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { getExampleYaml, KindLib } from './kindLib';
import { stringToYaml } from 'shared/yaml';
import { OneKubeItemTabUI } from './OneKubeItemTabUI';
import { Alert, Button, Collapse, Dropdown, notification, Result, Skeleton, Space, Tabs, Typography } from 'antd';
import { DeleteOutlined, DownOutlined, FlagOutlined } from '@ant-design/icons';
import { spaceWidth, buttonBorder, buttonSize } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { iCodeObjectBasic, PatchYamlAction, patchYamlCode } from './utils/yaml-tools';
import { iDeployment } from 'shared/deployment';
import { SplitterComp } from 'components/SharedComponents/Splitter/SplitterComp';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';

interface iCodeObjectArray extends iCodeObjectBasic {
  ui: any;
  helpers?: any;
}

interface iYamlEditorProps {
  deployment: iDeployment;
  fileName: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  mainKindType?: string;
  emptyButtonTitle?: any;
  emptyTitle?: any;
  emptyDescription?: any;
  emptyIco?: any;
  hideOtherObjectTypes?: boolean;
}

const { Title, Text } = Typography;
const { Panel } = Collapse;

const EmptyLeftColumnUI = (props: {
  emptyButtonTitle?: any;
  emptyTitle?: any;
  emptyDescription?: any;
  emptyIco?: any;
  hideOtherObjectTypes?: boolean;
  mainKindType?: string;
  AddNewYamlItem: (kind: string) => void;
  addYamlDropdown: any;
}) => {
  const resultSubTitle = (
    <Space direction="vertical">
      <Text> On the left column there will be a configurator with a visual interface. </Text>
      <Text> On the right column there will be a final yaml template for kubernetes. </Text>
      <Text> If you have a ready-made yaml template, then paste it into a text editor. </Text>
      <Text> If you only have a Docker image, then start the configuration by creating a application. </Text>
      <Text> You can also create other types of kubernetes objects. </Text>
    </Space>
  );

  return (
    <Space style={spaceWidth}>
      <Result
        icon={props.emptyIco || <FlagOutlined />}
        title={props.emptyTitle || <Title level={5}> Here you can configure your service using a visual interface. </Title>}
        subTitle={props.emptyDescription || resultSubTitle}
        extra={
          <Space direction="horizontal">
            <Button type="primary" onClick={e => props.AddNewYamlItem(props.mainKindType || 'deployment')} style={buttonBorder}>
              {props.emptyButtonTitle || `Create ${props.mainKindType || 'deployment'}`}
            </Button>
            {!props.hideOtherObjectTypes && props.addYamlDropdown}
          </Space>
        }
      />
    </Space>
  );
};

/**
 * @param param0
 * @returns
 * @link https://www.freecodecamp.org/news/how-to-build-react-based-code-editor/
 * @link https://www.npmjs.com/package/react-simple-code-editor
 */
export const YamlEditor = (props: iYamlEditorProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const project = props.deployment.ProjectModel;

  const [content, fError, fLoader] = useApiQuery(() => projectService.getFileContent(project.id, props.fileName), [props.fileName]);
  const [isChanged, setChanged] = useState(false);
  const [newCode, setNewCode] = useState(null);

  if (fLoader || fError) {
    return <Skeleton active={true} loading={true} />;
  }

  /**
   * Save all config to the file on the server backend
   * @param value
   */

  const handleSaveFile = async (value): Promise<boolean> => {
    setChanged(false);
    if (props.onSubmit) {
      props.onSubmit(value);
    }
    if (newCode) {
      await projectService.setFileContent(project.id, props.fileName, newCode);
      notification.success({ message: `Saved` });
      return true;
    }
    return false;
  };
  const yamlCodeString = newCode || content?.data || '';
  const yamlCodeObject = stringToYaml(yamlCodeString);
  const yamlCodeObjectArray: iCodeObjectArray[] = (Array.isArray(yamlCodeObject) ? yamlCodeObject : []).map(
    (yamlObj, indexInArray): iCodeObjectArray => {
      /* Here we will create a custom tabs for each object depending on kind */
      if (!yamlObj.kind) {
        return { obj: yamlObj, ui: <Alert type="warning" showIcon message="The property '.kind' is not defined" /> };
      }

      const kind = String(yamlObj.kind).toLocaleLowerCase();
      if (KindLib[kind] === undefined) {
        return { obj: yamlObj, ui: `For object with kind '${kind}' we do not help ui yet.` };
      }

      /**
       * Function to change any property in the current yaml object
       * @param key - path in the object
       * @param newObj - object to set or undefined if we want to unset
       * @param action - push, splice or undefined (set or unset)
       */
      const onChangeCurrentProperty = (key: string, newObj: any, action?: PatchYamlAction) => {
        yamlCodeObjectArray[indexInArray] = patchYamlCode<iCodeObjectArray>(yamlCodeObjectArray[indexInArray], key, newObj, action);
        const newValue = yamlCodeObjectArray.map(yamlObj => yaml.dump(yamlObj.obj)).join('\n---\n');

        setChanged(true);
        setNewCode(newValue);

        if (props.onChange) {
          props.onChange(newValue);
        }
      };

      const handleDeleteItemClick = () => {
        const newValue = yamlCodeObjectArray
          .filter((_, i) => i !== indexInArray)
          .map(yamlObj => yaml.dump(yamlObj.obj))
          .join('\n---\n');
        setChanged(true);
        setNewCode(newValue || '# You can paste your yaml template here');
        if (props.onChange) {
          props.onChange(newValue);
        }
      };

      let helpers = (
        <Space>
          <Button type="dashed" danger onClick={handleDeleteItemClick}>
            <DeleteOutlined />
          </Button>
        </Space>
      );

      // Custom tasbs for each object depending on kind
      const tabsArr = OneKubeItemTabUI(kind, yamlObj, onChangeCurrentProperty);

      return {
        obj: yamlObj,
        helpers: helpers,
        ui: tabsArr.length > 1 ? <Tabs tabPosition={'left'} defaultActiveKey="1" items={tabsArr} /> : tabsArr[0].children,
      };
    },
  );

  const hasContent = yamlCodeObjectArray.length !== 0 || yamlCodeObject.error !== undefined;

  /**
   * Allow to add new yaml item based on `KindLib` object
   * @param kind
   */
  const AddNewYamlItem = async (kind: string) => {
    const res = await getExampleYaml(kind);
    if (!res.status) {
      return;
    }
    const newCode = res.example + '\n---\n' + yamlCodeString;
    setChanged(true);
    setNewCode(newCode);
    if (props.onChange) {
      props.onChange(newCode);
    }
  };

  const addYamlDropdown = (
    <Dropdown
      menu={{
        onClick: e => AddNewYamlItem(e.key),
        items: Object.keys(KindLib).map((kind: string): any => {
          return { key: kind, label: KindLib[kind].name };
        }),
      }}
    >
      <Button icon={<DownOutlined />} iconPosition="end" style={buttonSize}>
        Add new item
      </Button>
    </Dropdown>
  );

  const handleEditorChange = _.debounce((value: string) => {
    try {
      const yaml = stringToYaml(value);
      if (yaml && !yaml.error) {
        debugger;
        setChanged(true);
        setNewCode(value);
        if (props.onChange) {
          props.onChange(value);
        }
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }, 1000);

  return (
    <Space direction="vertical" style={spaceWidth}>
      <SplitterComp
        left={
          <Space style={spaceWidth}>
            {!hasContent ? (
              <EmptyLeftColumnUI addYamlDropdown={addYamlDropdown} AddNewYamlItem={AddNewYamlItem} {...props} />
            ) : (
              <>
                {yamlCodeObject.error && (
                  <Alert
                    showIcon
                    type="error"
                    key="error-alert"
                    message={`${yamlCodeObject.error.name} at line ${yamlCodeObject?.error?.mark?.line} position ${yamlCodeObject?.error?.mark?.column}`}
                    description={<pre>{yamlCodeObject.error.message}</pre>}
                  />
                )}
                <Collapse size="small" key="collapse" defaultActiveKey={yamlCodeObjectArray.map((v, i) => `panel${i}`)}>
                  {yamlCodeObjectArray.map((v, i) => (
                    <Panel header={`${v.obj.kind} ${v.obj?.metadata?.name}`} key={`panel${i}`} extra={v.helpers}>
                      {v.ui}
                    </Panel>
                  ))}
                </Collapse>
              </>
            )}
          </Space>
        }
        right={
          <Space direction="vertical" style={spaceWidth}>
            <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
            <Text />
            <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
              <Editor
                height={isFullscreen ? '100vh' : 'calc(80vh - 215px)'}
                width={`100%`}
                language={`yaml`}
                value={yamlCodeString} // @todo: rebuild component only if yaml was significantly changed (cut space or empty lines or comments is not significant)
                theme={`GitHub`}
                defaultValue={`# If you have a ready-made yaml template, then paste it here.`}
                onChange={handleEditorChange}
              />
            </FullScreenEditor>
          </Space>
        }
      />
      {hasContent && (
        <BottomButtons>
          <Button type="primary" htmlType="submit" onClick={handleSaveFile} disabled={!isChanged}>
            Save
          </Button>
          <DeployButton deployment={props.deployment} beforeDeploy={handleSaveFile} />
          {addYamlDropdown}
        </BottomButtons>
      )}
    </Space>
  );
};
