import { apiQuery } from '../utils/common';

class SchemaFormService {
  async getProjectCreate() {
    return apiQuery('GET', `/api/schemaForm/projectCreate`);
  }

  async getGeneral(id: number) {
    return apiQuery('GET', `/api/schemaForm/general/${id}`);
  }

  async getDeploymentServiceForm(deploymentId: number, tabType: string, serviceName: string) {
    return apiQuery('GET', `/api/schemaForm/deployment-services/${deploymentId}/${tabType}/${serviceName}`);
  }

  async getServiceForm(projectId: number, tabType: string, serviceName: string) {
    return apiQuery('GET', `/api/schemaForm/services/${projectId}/${tabType}/${serviceName}`);
  }
  async getNewServiceForm(projectId: number) {
    return apiQuery('GET', `/api/schemaForm/services/${projectId}`);
  }

  async getEnv(id: number) {
    return apiQuery('GET', `/api/schemaForm/env/${id}`);
  }

  async getMap(id: number) {
    return apiQuery('GET', `/api/schemaForm/map/${id}`);
  }
}

const schemaFormService = new SchemaFormService();
export { schemaFormService, SchemaFormService };
