import { Select } from 'antd';
import { k8sVersionsList } from 'shared/k8sVersions';
import { spaceWidth } from 'utils/styles';

export function K8sVersions(props: { defaultValue: string; onChange: any }) {
  return (
    <Select placeholder="Select Kubernate version Here" defaultValue={props.defaultValue} style={spaceWidth} onChange={props.onChange}>
      {k8sVersionsList.map(version => (
        <Select.Option value={version} key={version}>
          {version}
        </Select.Option>
      ))}
    </Select>
  );
}
