import './Overview.scss';
import gql from 'graphql-tag';
import Markdown from 'react-markdown';
import DeploymentUptimeComponent from 'components/ChartRenderer/DeploymentUptimeComponent';
import { useGqlDeploymentHasMonitoring } from '../../../services/deployment.service';
import { MonitoringStatus } from '../../Deployments/MonitoringStatus/MonitoringStatus';
import { iDeployment } from 'shared/deployment';
import { DeploymentNotes } from '../DeploymentNotes';
import { useAuthedQuery } from 'utils/qlAuth';
import { ResourcesCard } from '../ResourcesStates/ResourcesData';
import { Row, Col, Card, Collapse, CollapseProps, theme, Modal, Space } from 'antd';
import { bottomMargin } from 'utils/styles';
import OverViewHeaderInfo from '../../Projects/OverViewHeader';
import { CaretRightOutlined, EditOutlined } from '@ant-design/icons/lib';
import type { CSSProperties } from 'react';
import DeploymentEnvVariablesForm from '../Setting/EnvironmentVariable/EnvironmentVariable';
import { useState } from 'react';
import { ProjectDocs } from '../../Projects/Settings/ProjectDocs';

export default function OverViewV2(props: { deployment: iDeployment }) {
  const { deployment } = props;

  const hasMonitoringRoutes = useGqlDeploymentHasMonitoring(props.deployment.id);

  const projectDocsQl = useAuthedQuery(
    gql`
      query DeploymentsController_getProjectDocs($deploymentId: Int!) {
        DeploymentsController_getProjectDocs(deploymentId: $deploymentId) {
          docs
        }
      }
    `,
    { variables: { deploymentId: Number(deployment.id) } },
  );

  const projectDocs = projectDocsQl.data?.DeploymentsController_getProjectDocs?.docs || '';

  const minutesData = [
    { minutes: 60, title: '60 min uptime' },
    { minutes: 15, title: '15 min uptime' },
    { minutes: 5, title: '5 min uptime' },
    { minutes: 3, title: '3 min uptime' },
  ];
  const { token } = theme.useToken();
  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    projectDocsQl.refetch({ deploymentId: Number(deployment.id) });
    setIsModalOpen(false);
  };

  const handleOk = () => setIsModalOpen(false);

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = panelStyle => [
    {
      key: '1',
      label: 'ENV list',
      children: <DeploymentEnvVariablesForm deployment={deployment} onSave={handleOk} />,
      // style: panelStyle,
    },
  ];

  return (
    <>
      <Row gutter={24}>
        <Col span={16}>
          <OverViewHeaderInfo deployment={deployment} />
        </Col>
        <Col span={8}>
          {hasMonitoringRoutes && (
            <>
              <Space size="large">
                <Card key={'minutes'} bordered={false}>
                  {minutesData.map(({ minutes, title }) => (
                    <Card key={minutes} style={{ border: '0px', margin: '-15px 0px', textAlign: 'center', float: 'left' }}>
                      <DeploymentUptimeComponent deploymentId={deployment.id} minutes={minutes} title={title} size="big" />
                    </Card>
                  ))}
                </Card>
              </Space>
              <br />
            </>
          )}
          <br />
        </Col>
        <Col span={24} style={{ marginBottom: '15px' }}>
          <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{ background: token.colorBgContainer }}
            items={getItems(panelStyle)}
          />
        </Col>
        <Col span={12}>
          <ResourcesCard deploymentId={deployment.id} />
          <br />
        </Col>
        <Col span={12}>
          <MonitoringStatus id={deployment.id} />

          <DeploymentNotes deployment={deployment} />
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Notes</span>
                <EditOutlined style={{ cursor: 'pointer' }} onClick={showModal} />
              </div>
            }
            style={bottomMargin}
          >
            {projectDocs ? (
              <Markdown>{projectDocs}</Markdown>
            ) : (
              <div>No documents found.</div> // Or a more informative message
            )}
          </Card>
        </Col>
      </Row>

      <Modal title="Edit Notes" open={isModalOpen} onCancel={handleCancel} footer={null} width={'90vw'}>
        <ProjectDocs deployment={deployment} isModal={true} hideModal={() => handleCancel()} />
      </Modal>
    </>
  );
}
