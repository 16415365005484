import { DeploymentTag } from '../Deployments/DeploymentTag';
import { imageStyle } from '../../utils/styles';
import { Image, Space } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined, LinkOutlined } from '@ant-design/icons/lib';
import { iDeployment } from 'shared/deployment';
import { useGqlDeploymentHasWebRoutes } from 'services/deployment.service';

const OverViewHeaderInfo = (props: { deployment: iDeployment }) => {
  const hasRoutes = useGqlDeploymentHasWebRoutes(props.deployment.id);

  const cloudProvider = props.deployment.RegionModel.cloudProvider;
  let lastUpdateTime = props.deployment?.NamespaceModel?.lastSyncTime;
  const lastUpdate = moment(lastUpdateTime).format('h:mm A on DD/MM/YYYY');

  console.log(props.deployment);
  const deploymentImages =
    cloudProvider === 'aws'
      ? 'branding/aws.png'
      : cloudProvider === 'azure'
      ? 'branding/azure.svg'
      : cloudProvider === 'gcp'
      ? 'branding/gsp.svg'
      : 'branding/kubernetes.svg';

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <div>
          <Image src={deploymentImages} alt={'Cloud logo'} preview={false} style={imageStyle} /> {props.deployment.RegionModel.name}
        </div>
        {` `}
        <div>
          <ClockCircleOutlined />
          {` `}
          {lastUpdate}
        </div>
      </Space>
      <Space direction="horizontal">
        {hasRoutes && (
          <>
            <LinkOutlined />
            <a href={`https://${props.deployment.domain}`} target="_blank">
              {props.deployment.domain}
            </a>
          </>
        )}
        <DeploymentTag key={`DeploymentTag`} deployment={props.deployment} />
      </Space>{' '}
    </Space>
  );
};

export default OverViewHeaderInfo;
