import { ReactElement } from 'react';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { ConfigurationServices } from './services/ConfigurationServices';
import { NewService } from './services/NewService';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { ConfigurationSettings } from './ConfigurationSettings';

interface iTemplateSettingsRoutes {
  tabName: string;
  serviceName: string;
  project: iProjectModel;
  deployment: iDeployment;
}

export const TemplateSettingsRoutes = (props: iTemplateSettingsRoutes): ReactElement => {
  if (props.tabName === 'new-service') {
    return <NewService deployment={props.deployment} />;
  }
  if (props.tabName === 'services') {
    if (!props.tabName) {
      return <NewService deployment={props.deployment} />;
    }
    return <ConfigurationServices deployment={props.deployment} serviceName={props.serviceName} />;
  }

  return <ConfigurationSettings deployment={props.deployment} settingsTab={props.tabName} />;
};
