import { Link } from 'react-router-dom';
import { Button, Result, Space, Typography } from 'antd';
import { CaretLeftFilled } from '@ant-design/icons';
import { blackText, noPageFoundStyle } from 'utils/styles';
import { getMainDomainUrl } from 'utils/common';
const { Title, Text } = Typography;

interface iNotFoundPageProps {
  title?: string;
  navigateTo?: string;
}

const NotFoundPage = (props: iNotFoundPageProps) => {
  const resultTitle = (
    <Title level={4} style={blackText}>
      {props.title ? props.title : 'No Data found'}
    </Title>
  );

  const resultSubTitle = (
    <Text style={blackText}>
      {`The content you are looking for does not exist. Please click below to navigate back to the ${props.navigateTo ? props.navigateTo : 'Home'}.`}
    </Text>
  );

  const resultExtra = props.navigateTo ? (
    <a href={`https://${getMainDomainUrl()}`}>
      <Button type="primary" icon={<CaretLeftFilled />}>
        {`Back to ${props.navigateTo} `}
      </Button>
    </a>
  ) : (
    <Link to="/">
      <Button type="primary" icon={<CaretLeftFilled />}>
        Back to Home
      </Button>
    </Link>
  );

  return (
    <Space style={noPageFoundStyle}>
      <Result status="404" title={resultTitle} subTitle={resultSubTitle} extra={resultExtra} />
    </Space>
  );
};

export default NotFoundPage;
