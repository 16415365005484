import { Alert, Space } from 'antd';
import { iProject } from 'interface/project';
import { OneFile } from '../FileEditor/OneFile';
import { PROJECT_CODE_NAME } from 'interface/common';
import { bottomMargin, spaceWidth } from 'utils/styles';
import { useState } from 'react';
import { iDeployment, iProjectModel } from 'shared/deployment';

interface iProjectDocsProps {
  deployment: iDeployment;
  isModal?: any;
  hideModal?: any;
}

export const ProjectDocs = (props: iProjectDocsProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  let height = '100vh';
  if (isFullscreen) {
    height = 'calc(100vh - 345px)';
  }
  if (props.isModal) {
    height = '50vh';
  }
  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert type="info" showIcon message={`You can add here any information. It will show in application overview page.`} style={bottomMargin} />
      <OneFile
        fileName={`${PROJECT_CODE_NAME}/deployment-docs.md`}
        deployment={props.deployment}
        // height={isFullscreen ? 'calc(100vh - 345px)' : '100vh'}
        height={height}
        hideModal={props.hideModal}
      />
    </Space>
  );
};
