import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { AutoComplete, Button, Input, notification, Skeleton, Space, Typography } from 'antd';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { awsRegionNames } from '../settings/TerraformJobsConfig';
import gql from 'graphql-tag';
import { isClusterNameValid } from 'shared/services';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';

const { Text } = Typography;

export const ClusterCreaionV2_awsTf = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [TF_AWS_ACCESS_KEY_ID, setTF_AWS_ACCESS_KEY_ID] = useState('');
  const [TF_AWS_SECRET_ACCESS_KEY, setTF_AWS_SECRET_ACCESS_KEY] = useState('');
  const [TF_AWS_region, setRegion] = useState('');

  const [createCluster, createClusterResult] = useAuthedMutationWithNotification(gql`
    mutation RegionsController_createAwsEksCluster(
      $name: String!
      $TF_AWS_ACCESS_KEY_ID: String!
      $TF_AWS_SECRET_ACCESS_KEY: String!
      $TF_AWS_region: String!
    ) {
      RegionsController_createAwsEksCluster(
        name: $name
        TF_AWS_ACCESS_KEY_ID: $TF_AWS_ACCESS_KEY_ID
        TF_AWS_SECRET_ACCESS_KEY: $TF_AWS_SECRET_ACCESS_KEY
        TF_AWS_region: $TF_AWS_region
      ) {
        id
      }
    }
  `);

  const regionId = createClusterResult?.data?.RegionsController_createAwsEksCluster?.id;
  if (regionId) {
    history.push(`/cluster/install/${regionId}/tf-aws`);
    return <Skeleton active={true} loading={true} />;
  }

  const onSave = async () => {
    if (!name) {
      notification.error({ message: `Cluster name is required` });
      return;
    }

    if (!isClusterNameValid(name)) {
      notification.error({ message: `Cluster name is invalid` });
      return;
    }

    if (!TF_AWS_ACCESS_KEY_ID) {
      notification.error({ message: `Cluster AWS_ACCESS_KEY_ID is required` });
      return;
    }

    if (!TF_AWS_SECRET_ACCESS_KEY) {
      notification.error({ message: `Cluster AWS_SECRET_ACCESS_KEY is required` });
      return;
    }

    if (!TF_AWS_region) {
      notification.error({ message: `Cluster region is required` });
      return;
    }

    const clusterData = {
      name: name,
      TF_AWS_ACCESS_KEY_ID: TF_AWS_ACCESS_KEY_ID,
      TF_AWS_SECRET_ACCESS_KEY: TF_AWS_SECRET_ACCESS_KEY,
      TF_AWS_region: TF_AWS_region,
    };
    createCluster({
      variables: clusterData,
    });
  };

  return (
    <Space direction="vertical" style={spaceWidth} data-qa={`ClusterCreaionV2_awsTf`}>
      <TitleUIRow title={`Configure new cluster`} />
      <Text strong> Cluster name </Text>
      The name of the cluster. It will be used in the UI and in the API.
      <Input
        placeholder="Cluster name"
        value={name}
        type="string"
        onChange={e => {
          setName(e.target.value);
        }}
      />
      <Text strong> AWS_ACCESS_KEY_ID </Text>
      <Input
        placeholder="AWS_ACCESS_KEY_ID"
        value={TF_AWS_ACCESS_KEY_ID}
        type="string"
        onChange={e => {
          setTF_AWS_ACCESS_KEY_ID(e.target.value);
        }}
      />
      <Text strong> AWS_SECRET_ACCESS_KEY </Text>
      <Input
        placeholder="TF_AWS_SECRET_ACCESS_KEY"
        value={TF_AWS_SECRET_ACCESS_KEY}
        type="password"
        onChange={e => {
          setTF_AWS_SECRET_ACCESS_KEY(e.target.value);
        }}
      />
      <Text strong> AWS region </Text>
      <AutoComplete
        options={awsRegionNames.map(region => ({ value: region }))}
        filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
        onSelect={value => {
          setRegion(value);
        }}
      >
        <Input
          placeholder="Select AWS region here"
          onChange={e => {
            setRegion(e.target.value);
          }}
        />
      </AutoComplete>
      <BottomButtons>
        <Space>
          <Button type="primary" data-qa="ClusterCreaionV2" onClick={onSave} style={buttonBorder}>
            Save & Continue
          </Button>
          <Button type="default" onClick={() => history.push('/first-step')}>
            Back
          </Button>
        </Space>
      </BottomButtons>
    </Space>
  );
};
