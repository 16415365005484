import gql from 'graphql-tag';
import { iDeployment } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { Space, Tag } from 'antd';

interface iDeploymentTagProps {
  deployment: iDeployment;
}

/**
 * Render deployment tags and project tags for the deployment
 * @param props
 * @returns
 */

export const DeploymentTag = (props: iDeploymentTagProps) => {
  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );
  const tags = data?.TagsController_tagsList || [];

  return (
    <>
      {(props?.deployment?.tags || []).map((tag: number) => {
        const tagData = tags.find((t: { id: number }) => t.id === tag);
        return tagData ? (
          <Tag key={tagData.name} color={tagData.color}>
            {tagData.name}
          </Tag>
        ) : null;
      })}
      {(props?.deployment.ProjectModel.tags || []).map((tag: any, i: number) => (
        <Tag key={i}>{tag}</Tag>
      ))}
    </>
  );
};
