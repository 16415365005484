import AuditLogs from '../AuditLogs/AuditLogs';
import { useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { Skeleton } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { DeploymentTitle } from './DeploymentStatusPage';
export default function DeploymentActivityPage() {
  const params: any = useParams();
  const id = params.id;

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  if (!deployment) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <>
      <TitleUIRow title={<DeploymentTitle deployment={deployment} />} />
      <DeploymentWidgetsRow deployment={deployment} />
      <AuditLogs deployment={deployment} />
    </>
  );
}
