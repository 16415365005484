import gql from 'graphql-tag';
import Meta from 'antd/es/card/Meta';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { useUser } from 'utils/common';
import { Skeleton, Button, Card, Flex, Space, Typography, Col, Row, Input, Tag, Result } from 'antd';
import { AppstoreOutlined, CaretLeftFilled, UnorderedListOutlined } from '@ant-design/icons';
import { buttonBorder, projectCardHeight, spaceWidth, topMargin } from 'utils/styles';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { TipBottomRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Title, Text } = Typography;
const { Search } = Input;

export const getDescription = (description: string, isCompactView: boolean) => {
  const compDesc = isCompactView && description.length > 25;
  const descriptionTooltip = <TipBottomRight tip={description}> {description.slice(0, 25)}... </TipBottomRight>;
  return !compDesc ? description : compDesc ? descriptionTooltip : description;
};

/**
 *@deprecated - not in use
 */
export const Projects = () => {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
          creationType
        }
      }
    `,
    {},
  );

  if (loading || error) return <Skeleton active={true} loading={true} />;

  const projects = data?.ProjectController_getProjectList || [];
  const filteredProjects = projects.filter(
    (project: { title: string; id: { toString: () => string } }) =>
      project.title.toLowerCase().includes(searchQuery.toLowerCase()) || project.id.toString().toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const newProjectFormButton = (
    <Button type="primary" style={buttonBorder}>
      New Template
    </Button>
  );

  const searchViews = () => (
    <Row>
      <Col span={10}>
        <Search placeholder="Search using project name or ID" enterButton allowClear onChange={e => setSearchQuery(e.target.value)} />
      </Col>
      <Col span={14}>
        <Flex justify="flex-end">
          <Space direction="horizontal">
            <TipTop tip="List View">
              <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
            </TipTop>
            <TipTop tip="Grid View">
              <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
            </TipTop>
            <Space>
              <Text />
            </Space>
            {/* {userHasPermission(user, UserPermissions.ProjectsMange) && <NewProjectForm openBtnUI={newProjectFormButton} />} */}
          </Space>
        </Flex>
      </Col>
    </Row>
  );

  const renderProject = () => {
    if (filteredProjects.length === 0) {
      const noResults = (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Templates
        </Button>
      );
      return (
        <Col span={24}>
          <Result status="404" subTitle="This Project does not exist... Please search for appropriate Project" extra={noResults} style={topMargin} />
        </Col>
      );
    }

    return (
      <>
        <Text />
        <Row gutter={[16, 16]}>
          {filteredProjects.map(project => {
            const { logo, title, description, id, tags, creationType } = project;
            const projectDescription = (
              <Meta avatar={logo && <LogoAvatar logo={logo} name={title} />} description={getDescription(description, isCompactView)} />
            );

            const projectCardExtra = <Tag> {id} </Tag>;

            const projectDetails = () => (
              <>
                <Col span={20}> {projectDescription} </Col>
                <Col span={4}>
                  <Flex justify="flex-end">
                    {(tags || []).map((tag: string) => (
                      <Tag key={tag}> {tag} </Tag>
                    ))}
                    {creationType && (
                      <Tag key="creationType" color="cyan">
                        {creationType}
                      </Tag>
                    )}
                  </Flex>
                </Col>
              </>
            );

            const helpContent = [
              {
                type: 'deployment-clone',
                title: 'Why I have this project?',
                description: [
                  'This project was created automatically by the system for you when you requests a new application based on preconfigured project.',
                  'It is a template for your deployments. You can modify it or run `git pull` to get updates from the original project template.',
                ],
              },
              {
                type: 'cluster-clone',
                title: 'Why I have this project?',
                description: [
                  'This project was created automatically by the system for you when you requests install integrations for your cluster.',
                  'It is a template for your deployments. You can modify it or run `git pull` to get updates from the original project template.',
                ],
              },
            ];

            const cardTitleClone = (
              <>
                {title}
                &nbsp;
                {helpContent.map(({ type, title, description }) =>
                  creationType === type ? (
                    <HelpDrawerTpl key={type} title="Reason">
                      <Title level={3}> {title} </Title>
                      {description.map((text, index) => (
                        <Text key={index}> {text} </Text>
                      ))}
                    </HelpDrawerTpl>
                  ) : null,
                )}
              </>
            );

            const projectsContent = () => (
              <Col span={isCompactView ? 8 : 24} style={spaceWidth}>
                <Link to={`/project/${id}`}>
                  <Card
                    key={id}
                    size="small"
                    type="inner"
                    bordered={false}
                    title={cardTitleClone}
                    extra={projectCardExtra}
                    style={isCompactView ? projectCardHeight : ''}
                  >
                    <Row data-cy={id}>
                      {(!tags || tags.length === 0) && projectDescription}
                      {tags && tags.length > 0 && projectDetails()}
                    </Row>
                  </Card>
                </Link>
              </Col>
            );
            return projectsContent();
          })}
        </Row>
        <Text />
        <Text />
      </>
    );
  };
  return (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      {renderProject()}
    </Space>
  );
};
