import gql from 'graphql-tag';
import moment from 'moment';
import { useState, useMemo } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { iDeployment } from 'shared/deployment';
import { Button, Col, Flex, Row, Select, Skeleton, Space, Table, Typography } from 'antd';
import { ReloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { spaceWidth } from 'utils/styles';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface iDeploymentk8sEventsListProps {
  deployment: iDeployment | undefined;
  podName?: string;
}

const { Text } = Typography;
const { Option } = Select;

export function Deploymentk8sEventsList(props: iDeploymentk8sEventsListProps) {
  const [hovered, setHovered] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(props.podName || undefined);

  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ApiAgentController_listNamespacedEvent($deploymentId: Int!) {
        ApiAgentController_listNamespacedEvent(deploymentId: $deploymentId) {
          status
          message
          events
        }
      }
    `,
    { skip: !props?.deployment?.id, variables: { deploymentId: props?.deployment?.id } },
  );

  let Deploymentk8sEventsList: any[] = data?.ApiAgentController_listNamespacedEvent?.events || [];
  const uniqueEventNames = useMemo(() => {
    return Array.from(new Set(Deploymentk8sEventsList.map(event => event.involvedObject.name)));
  }, [Deploymentk8sEventsList]);

  const eventActions = () => {
    const handleFilterChange = (value: string) => setFilter(value === 'RESET' ? undefined : value);

    const eventFilter = (
      <Col span={21}>
        <Select placeholder="Filter by pod name" showSearch value={filter || undefined} onChange={handleFilterChange} style={spaceWidth}>
          <Option value="RESET">
            <Flex justify="flex-end">
              <Text strong type="warning">
                Reset <RollbackOutlined />
              </Text>
            </Flex>
          </Option>
          {uniqueEventNames.map((name, index) => (
            <Option key={index} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
    );

    const eventRefresh = (
      <Col span={2}>
        <Flex justify="flex-end">
          <TipLeft tip="Click here to refresh the events">
            <Button
              type="dashed"
              icon={<ReloadOutlined spin={hovered} />}
              onClick={async () => await refetch()}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              Refresh
            </Button>
          </TipLeft>
        </Flex>
      </Col>
    );

    return (
      <Row>
        {eventFilter}
        <Col span={1} />
        {eventRefresh}
      </Row>
    );
  };

  let filteredEvents = Deploymentk8sEventsList;
  if (filter) {
    filteredEvents = Deploymentk8sEventsList.filter(event => event.involvedObject.name.includes(filter));
  }

  const timeFormat = (timestamp: string) => moment(timestamp).format('DD-MM-YYYY ~ hh:mm:ss A');
  const columns = [
    { title: 'Pod name', dataIndex: ['metadata', 'name'], key: 'name' },
    { title: 'Status', dataIndex: 'type', key: 'type' },
    { title: 'Count', dataIndex: 'count', key: 'count' },
    { title: 'Reason', dataIndex: 'reason', key: 'reason' },
    { title: 'Event message', dataIndex: 'message', key: 'message' },
    { title: 'Last Time', dataIndex: 'lastTimestamp', key: 'lastTimestamp', render: timeFormat },
  ];

  if (!props.deployment || loading) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <Space direction="vertical" style={spaceWidth}>
      {props.podName === undefined && eventActions()}
      <Table
        columns={columns}
        dataSource={filteredEvents.sort((a, b) => {
          return new Date(b.lastTimestamp).getTime() - new Date(a.lastTimestamp).getTime();
        })}
        rowKey={record => record.involvedObject.uid}
      />
    </Space>
  );
}
