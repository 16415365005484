import { ReactElement, useEffect, useState } from 'react';
import { Skeleton, Tabs, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { ProjectFiles } from 'components/Projects/FileEditor/ProjectFiles';
import { ProjectContainerStorage } from 'components/Projects/ProjectContainerStorage/ProjectContainerStorage';
import { ProjectGitSettings } from 'components/Projects/Settings/Git/ProjectGitSettings';
import { ProjectDocs } from 'components/Projects/Settings/ProjectDocs';
import { ProjectVersions } from 'components/Projects/Settings/ProjectVersion';
import { ShareProject } from 'components/Projects/Settings/ShareProject';
import { isInAdvancedMode, setAdvancedMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { DeleteTab } from 'components/Deployments/Setting/General/settings/DeleteTab';
import { OverviewForNotReadyDeployment } from 'components/Deployments/New/OverviewForNotReadyDeployment';
import DeploymentEnvVariablesForm from 'components/Deployments/Setting/EnvironmentVariable/EnvironmentVariable';
import { CronJobsUI } from 'components/Deployments/Setting/General/CronJobs';
import DeploymentUpdateVersionForm from 'components/Deployments/Setting/General/DeploymentUpdateVersion';
import { IngressAnnotationForm } from 'components/Deployments/Setting/IngressAnnotationForm/IngressAnnotationForm';
import { Storage_S3 } from 'components/Deployments/Setting/General/Storage';
import { NamespaceConfiguration } from 'components/Projects/Settings/NamespaceConfiguration';
import { BranchesOutlined } from '@ant-design/icons';

interface iConfigurationSettings {
  deployment: iDeployment;
  settingsTab: string;
}

const { TabPane } = Tabs;

export const ConfigurationSettings = (props: iConfigurationSettings): ReactElement => {
  const history = useHistory();
  const [isAdvancedMode, setIsAdvancedMode] = useState(isInAdvancedMode(`dp`, props.deployment.id));
  const user = useUser();
  const [tabName, setTabName] = useState(props.settingsTab);

  const advancedTabs = [`git`, `docs`, `share`, `ci-cd`, `project-files`, `namespace`, `storage`, `cronjobs`, `ingress`];

  useEffect(() => {
    setTabName(props.settingsTab);
    if (advancedTabs.includes(props.settingsTab)) {
      setIsAdvancedMode(true);
      setAdvancedMode(`dp`, props.deployment.id, true);
    }
  }, [props.settingsTab, isAdvancedMode]);

  const project = props.deployment.ProjectModel;
  const deployment = props.deployment;

  let defaultTab = tabName;
  const Permit_ProjectsMange = userHasPermission(user, UserPermissions.ProjectsMange);

  if (!defaultTab) {
    if (deployment.isReady === false) {
      defaultTab = 'general';
    } else {
      defaultTab = 'danger-zone';
    }
  }

  if (!tabName) {
    debugger;
    history.push(`/app/${deployment.id}/configuration/${defaultTab}`);
    return null;
  }

  if (!deployment || !user) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleTabClick = (key: any) => {
    history.push(`/app/${deployment.id}/configuration/settings/${key}`);
    setTabName(key);
  };

  const gitTabs = [
    deployment.isReady === true ? { key: 'danger-zone', tab: 'Danger zone', component: <DeleteTab id={deployment.id} /> } : null,
    deployment.isReady === false
      ? {
          permission: Permit_ProjectsMange,
          key: 'general',
          tab: 'General',
          component: <OverviewForNotReadyDeployment deployment={deployment} />,
        }
      : null,

    {
      permission: Permit_ProjectsMange,
      key: 'git',
      tab: (
        <>
          <BranchesOutlined /> GIT
        </>
      ),
      component: <ProjectGitSettings deployment={deployment} />,
    },
    { permission: Permit_ProjectsMange, key: 'docs', tab: 'Docs', component: <ProjectDocs deployment={deployment} /> },
    deployment.isReady === false
      ? { permission: Permit_ProjectsMange, key: 'share', tab: 'Share', component: <ShareProject project={project} /> }
      : null,
    { permission: Permit_ProjectsMange, key: 'project-files', tab: 'Files', component: <ProjectFiles deployment={deployment} /> },
    {
      permission: Permit_ProjectsMange,
      key: 'pull-secrets',
      tab: 'Pull secrets',
      component: <ProjectContainerStorage deployment={deployment} />,
    },
    { permission: Permit_ProjectsMange, key: 'ci-cd', tab: 'CI/CD', component: <ProjectVersions project={project} /> },
    {
      permission: Permit_ProjectsMange,

      key: 'namespace',
      tab: 'Namespace',
      component: <NamespaceConfiguration deployment={deployment} />,
    },

    {
      permission: Permit_ProjectsMange,

      key: 'env',
      tab: 'Environment variables',
      component: <DeploymentEnvVariablesForm deployment={deployment} />,
      condition: true, //deployment.ProjectModel?.showEnvVarsTab !==  false
    },
    {
      permission: Permit_ProjectsMange,

      key: 'version',
      tab: 'Version',
      component: <DeploymentUpdateVersionForm deployment={deployment} />,
      condition: deployment.ProjectModel?.showVerionsTab !== false,
    },

    // Only for nanoheal project
    {
      permission: Permit_ProjectsMange,

      key: 'storage',
      tab: 'Storage',
      component: <Storage_S3 deployment={deployment} />,
      condition: deployment.ProjectModel.name === 'nanoheal',
    },
    // Only for nanoheal project
    {
      permission: Permit_ProjectsMange,

      key: 'cronjobs',
      tab: 'Cron jobs',
      component: <CronJobsUI deployment={deployment} />,
      condition: deployment.ProjectModel.name === 'nanoheal',
    },
    // Only for nanoheal project
    {
      permission: Permit_ProjectsMange,

      key: 'ingress',
      tab: 'Ingress',
      component: <IngressAnnotationForm deployment={deployment} />,
      condition: deployment.ProjectModel.name === 'nanoheal',
    },
  ];

  const gitTabsFiltered = gitTabs
    .filter(Boolean)
    .filter(v => v.permission !== false && v.condition !== false)
    .filter(v => advancedTabs.includes(v.key) === isAdvancedMode || advancedTabs.includes(v.key) === false);

  const realTab = tabName || defaultTab;
  return (
    <Tabs
      defaultActiveKey={realTab}
      onChange={handleTabClick}
      tabBarExtraContent={
        <Switch
          checkedChildren="Advanced Mode"
          unCheckedChildren="Basic mode"
          onClick={val => {
            setAdvancedMode(`dp`, props.deployment.id, val);
            if (val) {
              setIsAdvancedMode(true);
            } else {
              if (advancedTabs.includes(realTab)) {
                history.push(`/app/${deployment.id}/configuration/settings/general`);
                setIsAdvancedMode(false);
                setTabName(`general`);
              } else {
                setIsAdvancedMode(false);
              }
            }
          }}
          checked={isAdvancedMode}
        />
      }
    >
      {gitTabsFiltered.map(tabVal => (
        <TabPane key={tabVal.key} tab={tabVal.tab}>
          {tabVal.component}
        </TabPane>
      ))}
    </Tabs>
  );
};
