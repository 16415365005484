import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { setPageWidgets } from 'services/actions';
import { iDeployment } from 'shared/deployment';
import { DeploymentErrors } from './DeploymentErrors';
import { Image, Popover, Space, Tag, Typography } from 'antd';
import { LockOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { DeploymentApplyWidget } from './SpecsRevisions/DeploymentApplyWidget';
import { DeploymentSelectionComponent } from 'components/Projects/DeploymentSelectionComponent/DeploymentSelectionComponent';
import { GitStatusComponent } from 'components/Projects/GitStatusComponent/GitStatusComponent';
import { DeployButton } from './Setting/new-deployment/DeployPage';

const { Text } = Typography;

const DeploymentClusterWidget = (props: { deployment: iDeployment }) => {
  if (!props.deployment.RegionModel) {
    return null;
  }
  const deployment = props.deployment;
  const cloudProvider: any = deployment.RegionModel ? deployment.RegionModel.cloudProvider : 'on-premise';
  const srcImg: any =
    cloudProvider === 'aws'
      ? 'branding/aws.png'
      : cloudProvider === 'azure'
      ? 'branding/azure.svg'
      : cloudProvider === 'gcp'
      ? 'branding/gsp.svg'
      : 'branding/kubernetes.svg';

  return (
    <Popover
      title="Region"
      trigger="hover"
      content={
        <Space direction="vertical">
          <Text> Name: {deployment?.RegionModel?.name} </Text>
          {deployment?.RegionModel?.natIp && <Text> {`Nat IP: ${deployment.RegionModel.natIp}`} </Text>}
        </Space>
      }
    >
      <Link to={`/clusters/${deployment.RegionModel?.id}`}>
        <Tag icon={<Image src={srcImg} alt={cloudProvider} preview={false} style={{ height: '15px' }} />}>{deployment?.RegionModel?.title}</Tag>
      </Link>
    </Popover>
  );
};

const DeploymentStatusWidget = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;
  return deployment.isReady && !deployment.isEnabled ? (
    <TipTop tip="Application is disabled. You can Enable it in Settings.">
      <Link to={`/app/${deployment.id}/settings/delete`}>
        <Tag color="orange" icon={<PauseCircleOutlined />}>
          On Pause
        </Tag>
      </Link>
    </TipTop>
  ) : null;
};
const DeploymentVersionWidget = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;

  return deployment.VersionModel ? (
    <Link to={`/app/${deployment.id}/configuration/settings/version`}>
      <Tag> {`Version: ${deployment.VersionModel.channel} ${deployment.VersionModel.name}`} </Tag>
    </Link>
  ) : null;
};

export const DeploymentWidgetsRow = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;
  const project = deployment?.ProjectModel;
  const history = useHistory();

  useEffect(() => {
    if (deployment && project) {
      const row = (
        <Space>
          <DeploymentSelectionComponent
            projectId={project.id}
            deployment={props.deployment}
            onSelect={async (deployment: iDeployment) => {
              const newPath = window.location.hash.replace('#/', '/').replace(/\/app\/[0-9]+\//, `/app/${deployment.id}/`);
              history.push(newPath);
            }}
          />

          {project?.gitInitialized === true && deployment?.isReady && (
            <GitStatusComponent projectId={project.id} deploymentId={props?.deployment?.id} />
          )}
          <DeploymentApplyWidget deployment={deployment} />
          <DeploymentErrors deployment={deployment} />
          <DeploymentVersionWidget deployment={deployment} />
          <DeploymentStatusWidget deployment={deployment} />
          <DeploymentClusterWidget deployment={deployment} />
          <DeployButton title="Deploy template" deployment={props.deployment} />
        </Space>
      );
      setPageWidgets(row);
    }

    return () => {
      setPageWidgets(null);
    };
  }, [deployment]);

  return null;
};
