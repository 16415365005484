import moment from 'moment';
import InfoUpdate from '../OverView/InfoUpdateComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useApiQuery, useUser } from '../../../utils/common';
import { auditLogsService } from 'services/auditLogs.service';
import { iDeployment } from 'shared/deployment';
import { TitleUIRow } from 'layout/TitleUI';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Typography, Collapse, Skeleton, Tag, Space, Alert, Table, Button, Modal, Col, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { autoOverflow, bannerStyle, spaceWidth } from 'utils/styles';
import { PROJECT_NAME } from 'interface/common';
import { TipBottom, TipRight } from 'components/SharedComponents/Tooltip/Tooltip';
import { formatUserName } from 'utils/nameFormat';
import { DataType } from 'ajv/dist/compile/validate/dataType';
import { useState } from 'react';

interface iDHistory {
  createdAt: moment.MomentInput;
  data: { diff: { [x: string]: any } };
  userName: any;
  name: string;
}

const { Text } = Typography;
const { Panel } = Collapse;

export default function AuditLogs(props: { deployment: iDeployment }) {
  // @ts-ignore
  let { id } = useParams();
  const history = useHistory();
  const user = useUser();
  const [dHistory, dHistoryError, dHistoryLoading] = useApiQuery(() => auditLogsService.getDeploymentLogsHistory(id));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editList, setEditList] = useState({});

  if (!userHasPermission(user, UserPermissions.DeploymentHistory)) return <UserNoPermissions permission={UserPermissions.DeploymentHistory} />;
  if (dHistoryError) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  console.log(dHistory);
  if (dHistory) {
    dHistory.map(elem => {
      if (elem.data.diff) {
        elem['diff'] = elem.data.diff;
      }
    });
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Diff',
      dataIndex: 'diff',
      key: 'diff',
      render: diff => {
        // Todo: use AuditDiff component
        return Object.keys(diff || []).map(key => {
          const dataKey = diff[key];
          if (dataKey && Object.keys(dataKey).length > 0) {
            if (key == 'env' || key == 'serviceVars') {
              return Object.keys(dataKey).length > 0 ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setEditList(dataKey);
                    showModal();
                  }}
                >
                  Show more
                </Button>
              ) : (
                ''
              );
            }
          }
          const newVal = dataKey === true ? 'true' : dataKey === false ? 'false' : JSON.stringify(dataKey);
          return (
            <li key={key}>
              <InfoUpdate keyElement={key} valueElement={newVal} />
            </li>
          );
        });
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'date',
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: text => <>{moment(text).format('DD-MM-YYYY ~ hh:mm:ss A')}</>,
    },
  ];
  return (
    <Space direction="vertical" style={spaceWidth}>
      <Skeleton active={true} loading={dHistoryLoading}>
        <TitleUIRow title={`Audit logs: ${props.deployment.name}`} />
        <Modal title="Show more" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={'80%'} style={{ maxHeight: '500px' }}>
          {Object.keys(editList || []).map(envKey => {
            const dataKeys = [
              { key: editList[envKey].old, color: 'gold', tip: 'Old env value' },
              { key: editList[envKey].new, color: 'green', tip: 'New env value' },
            ];

            const renderData = dataKeys
              .filter(({ key }) => key) // Filter out undefined keys
              .map(({ key, color, tip }) => (
                <Space direction="horizontal" key={envKey} style={{ marginBottom: '20px' }}>
                  <TipBottom tip="ENV name">
                    <Tag color="red">{envKey}</Tag>
                  </TipBottom>
                  <ArrowRightOutlined />
                  <TipBottom tip={tip}>
                    <Tag color={color}>{JSON.stringify(key)}</Tag>
                  </TipBottom>
                </Space>
              ));

            return (
              <div key={envKey}>
                <Space direction="vertical">{renderData}</Space>
              </div>
            );
          })}
        </Modal>
        <Table columns={columns} dataSource={dHistory} />
      </Skeleton>
      <Text />
    </Space>
  );
}
