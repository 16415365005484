import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { apiQuery, useDpApi, iBaseAjaxAnswer, iUseDpApi } from '../utils/common';

export interface ProjectServiceList {
  name: string;
}
export interface ProjectServiceListResponse extends iBaseAjaxAnswer<any> {
  data: ProjectServiceList[];
}

export interface iProjectGitStatus extends iBaseAjaxAnswer<any> {
  data: { status: any };
}

export const useProjectGetProjectService = (projectId: number, serviceName: string, deps: any[] = []): iUseDpApi<iCloudProjectServiceYamlSpecs> => {
  return useDpApi(() => projectService.getProjectService(projectId, serviceName), deps);
};

class ProjectService {
  // ENV
  getProjectEnv(projectId: number): Promise<iBaseAjaxAnswer<any>> {
    return apiQuery('GET', `/api/project/${projectId}/env`);
  }

  // ENV
  setProjectEnv(projectId: number, projectObj: any): Promise<iBaseAjaxAnswer<any>> {
    return apiQuery('POST', `/api/project/${projectId}/env`, projectObj);
  }

  // services
  getProjectService(projectId: number, serviceName: string): Promise<iBaseAjaxAnswer<iCloudProjectServiceYamlSpecs>> {
    return apiQuery('GET', `/api/project/${projectId}/service/${serviceName}`);
  }

  /** services
   * @deprecated - use gQL `ProjectController_getServiceList` instead
   */
  getProjectServiceList(projectId: number): Promise<ProjectServiceListResponse> {
    return apiQuery('GET', `/api/project/${projectId}/services`);
  }

  // services
  setProjectServices(projectId: number, serviceObj: any): Promise<iBaseAjaxAnswer<any>> {
    return apiQuery('POST', `/api/project/${projectId}/services`, serviceObj);
  }

  setProjectSettings(projectId: number, projectObj: any) {
    return apiQuery('POST', `/api/project/${projectId}/settings`, projectObj);
  }

  setNewService(projectId: number, serviceObj: object) {
    return apiQuery('POST', `/api/project/${projectId}/createService`, serviceObj);
  }

  // git-configuration
  saveProjectGitConfig(projectId: number, gitConfiguration: { gitUrl: string; gitBranch: string; gitToken: string; gitAuthType: string }) {
    return apiQuery('POST', `/api/project/${projectId}/git-configuration`, gitConfiguration);
  }

  // git-pull
  gitPullProject(projectId: number) {
    return apiQuery('POST', `/api/project/${projectId}/git-pull`, {});
  }

  // git-clone
  gitCloneProject(projectId: number) {
    return apiQuery('POST', `/api/project/${projectId}/git-clone`, {});
  }

  // git-push
  gitPushProject(projectId: number, dstBranch: string) {
    return apiQuery('POST', `/api/project/${projectId}/git-push`, {
      dstBranch: dstBranch,
    });
  }

  // git status
  gitStatusProject(projectId: number): Promise<iProjectGitStatus> {
    return apiQuery('GET', `/api/project/${projectId}/status`);
  }

  // project - new
  setNewProject(projectObj: object) {
    return apiQuery('POST', `/api/project/projectCreate`, projectObj);
  }
  getPublicProjectByUUID(uuid: string) {
    return apiQuery('GET', `/api/project/getPublicProjectByUUID/${uuid}`);
  }

  // project - delete
  deleteProject(projectId: number) {
    return apiQuery('DELETE', `/api/project/${projectId}`);
  }

  getFilesTree(projectId: number) {
    return apiQuery('GET', `/api/project/${projectId}/files-tree`);
  }
  getFileContent(projectId: number, fileName: string) {
    return apiQuery('POST', `/api/project/${projectId}/get-file`, { fileName });
  }
  setFileContent(projectId: number, fileName: string, data: string) {
    return apiQuery('POST', `/api/project/${projectId}/set-file`, { fileName, data });
  }
  getProjectLogs(projectId: number) {
    return apiQuery('POST', `/api/project/${projectId}/getProjectLogs`, { projectId: projectId });
  }

  serviceHelmAdd(projectId: number, serviceName: string): Promise<any> {
    return apiQuery('GET', `/api/project/${projectId}/service/${serviceName}/add`);
  }
  serviceHelmUpdate(projectId: number, serviceName: string): Promise<any> {
    return apiQuery('GET', `/api/project/${projectId}/service/${serviceName}/update`);
  }
  serviceHelmPull(projectId: number, serviceName: string): Promise<any> {
    return apiQuery('GET', `/api/project/${projectId}/service/${serviceName}/pull`);
  }
}

const projectService = new ProjectService();
export { projectService, ProjectService };
