import { Fragment, useState } from 'react';
import { PROJECT_NAME } from 'interface/common';
import { TenantConfiguration } from 'components/Tenant/TenantConfiguration';
import { Button, Divider, Drawer, Space, Typography } from 'antd';
import { ApiOutlined, BookOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';
import { buttonColor } from 'utils/styles';

const { Title, Text } = Typography;

const DrawersData = (props: { body: any; title: string; icon: any; hoverIcon: any }) => {
  const [drawerState, setDrawerState] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const hoverOn = () => setIsHovered(true);
  const hoverOff = () => setIsHovered(false);

  return (
    <>
      <Space role="button" onClick={() => setDrawerState(true)} onMouseEnter={hoverOn} onMouseLeave={hoverOff}>
        <TipBottom tip={props.title}>
          <Button type="text" icon={isHovered ? props.hoverIcon : props.icon} />
        </TipBottom>
      </Space>
      <Drawer width={300} title={props.title} onClose={() => setDrawerState(false)} open={drawerState}>
        {props.body}
      </Drawer>
    </>
  );
};

export const NhHelpDrawer = props => {
  const drawerHelp = (
    <Space direction="vertical">
      <Fragment key={`Need help?`}>
        <Title level={4}> Need help? </Title>
        <Space key="S1" direction="horizontal">
          <Button type="primary" icon={<QuestionCircleOutlined />} />
          <Text> Look out for this icon to access help and related resources. </Text>
        </Space>
        <Divider />
      </Fragment>
      <Fragment key={`guide`}>
        <Title level={4}> How to guide </Title>
        <Space key="S2" direction="vertical">
          <Text> Learn how to utilize {PROJECT_NAME} full potential using guided tutorials, and in-depth help guides. </Text>
          <a href="https://www.unifie.cloud/doc/docs/Overview/" target="_blank">
            <Button type="primary" block>
              Start Learning
            </Button>
          </a>
        </Space>
        <Divider />
      </Fragment>
      <Fragment key={`Help & Support`}>
        <Title level={4}> Help & Support </Title>
        <Space key="S3" direction="vertical">
          <Text> Reach out to our support team for further questions and assistance </Text>
          {/* <Button type="primary" block>
            Contact Us
          </Button> */}
        </Space>
      </Fragment>
    </Space>
  );

  return (
    <Space direction="horizontal">
      <a key={`docs`} href={`https://www.unifie.cloud/doc/docs/api-v1/`} target="_blank">
        <TipBottom tip="Api documentation" key="api">
          <Button type="text" icon={<BookOutlined />} />
        </TipBottom>
      </a>

      <a key={`api`} href={`https://${window.location.host}/unifie-api/graphql/v1`} target="_blank">
        <TipBottom tip="Api" key="api">
          <Button type="text" icon={<ApiOutlined />} />
        </TipBottom>
      </a>
      <Space key={`help`}>
        <DrawersData body={drawerHelp} title={'Help'} icon={<QuestionCircleOutlined />} hoverIcon={<QuestionCircleOutlined style={buttonColor} />} />
      </Space>
      <Space key={`themes`}>
        <DrawersData body={<TenantConfiguration />} title={'Theme'} icon={<SettingOutlined />} hoverIcon={<SettingOutlined style={buttonColor} />} />
      </Space>
    </Space>
  );
};
