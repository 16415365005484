import { Tooltip } from 'antd';

interface iTip {
  tip: any;
  children?: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right' | 'topLeft' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'rightTop';
}

const Tip = ({ tip, children, placement }: iTip) => (
  <Tooltip color="#00A58E" placement={placement} title={tip}>
    {children}
  </Tooltip>
);

const tips = (placement: iTip['placement']) => (props: Omit<iTip, 'placement'>) => <Tip {...props} placement={placement} />;

export const TipTop = tips('top');
export const TipBottom = tips('bottom');
export const TipLeft = tips('left');
export const TipRight = tips('right');
export const TipTopLeft = tips('topLeft');
export const TipBottomLeft = tips('bottomLeft');
export const TipBottomRight = tips('bottomRight');
export const TipLeftTop = tips('leftTop');
export const TipRightTop = tips('rightTop');
