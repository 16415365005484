import gql from 'graphql-tag';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from 'utils/common';
import { useAuthedQuery } from 'utils/qlAuth';
import { PermissionCheck } from 'components/Permissions/Permissions';
import { Button, Card, Col, Flex, Image, Input, Result, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { AppstoreOutlined, CaretLeftFilled, UnorderedListOutlined } from '@ant-design/icons';
import { buttonBorder, floatingLeft, floatingRight, shadowStyle, spaceWidth, topMargin } from 'utils/styles';
import { NoClusters } from 'components/SharedComponents/NoPage/NoPages';
import { TipBottom, TipBottomRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const { Search } = Input;

export const NewClusterCreation = () => {
  return (
    <Flex data-qa="ClustersList.tsx">
      <PermissionCheck
        permission={`new_cluster`}
        allow={
          <Link to={`/cluster/new`}>
            <Button type="primary" style={buttonBorder}>
              New Cluster
            </Button>
          </Link>
        }
        no={
          <Button type="primary" disabled style={buttonBorder}>
            New Cluster
          </Button>
        }
      />
    </Flex>
  );
};

export const ClustersList = () => {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchedClusters, setSearchedClusters] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { loading, error, data } = useAuthedQuery(gql`
    query RegionsController_getRegions {
      RegionsController_getRegions {
        id
        tenant
        name
        title
        kubernetesVersion
        cloudProvider
        isPublic
        configureStatus
        integrationsStatus
        allowToAddDeployments
        regionName
      }
    }
  `);

  const user = useUser();

  if (!user || loading || error) {
    return <Skeleton active={true} loading={true} />;
  }

  const clusters = [...(data?.RegionsController_getRegions || [])].sort((a, b) =>
    a.isPublic !== b.isPublic ? a.isPublic - b.isPublic : b.id !== a.id ? b.id - a.id : 0,
  );

  if (clusters.length <= 0) {
    return (
      <Space direction="vertical" style={spaceWidth}>
        <NoClusters />
      </Space>
    );
  }

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    const filteredClusters = clusters.filter(
      cluster => cluster.name.toLowerCase().includes(value.toLowerCase()) || cluster.id.toString().toLowerCase().includes(value.toLowerCase()),
    );
    setSearchedClusters(filteredClusters);
  };

  const clustersToRender = (searchQuery ? searchedClusters : clusters).sort((a, b) => a.id - b.id);

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Row>
        <Col span={10}>
          <Search placeholder="Search using cluster name" enterButton allowClear onChange={e => handleSearch(e.target.value)} />
        </Col>
        <Col span={14}>
          <Flex justify="flex-end">
            <Space>
              <TipTop tip="List View">
                <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
              </TipTop>
              <TipTop tip="Grid View">
                <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
              </TipTop>
              {NewClusterCreation()}
            </Space>
          </Flex>
        </Col>
      </Row>
      <Text />
      <Row gutter={[16, 16]}>
        {!clustersToRender?.length && (
          <Space direction="vertical" style={spaceWidth}>
            <Col span={24}>
              <Result
                status="404"
                subTitle="This Cluster does not exist... Please search for appropriate Cluster"
                extra={
                  <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
                    Back to Clusters
                  </Button>
                }
                style={topMargin}
              />
            </Col>
          </Space>
        )}
        {clustersToRender.map(region => {
          const { cloudProvider, isPublic, kubernetesVersion, allowToAddDeployments, name } = region;
          const servicesImage = `branding/logo${cloudProvider ? cloudProvider.charAt(0).toUpperCase() + cloudProvider.slice(1) : 'Kubernetes'}.svg`;

          const publicRegion = isPublic ? (
            <Tag color="grey" style={floatingLeft}>
              <TipBottom tip="Kubernetes Version"> Public </TipBottom>
            </Tag>
          ) : null;

          const clusterTags = (
            <Tag color={kubernetesVersion ? 'green' : 'red'} style={floatingRight}>
              <TipBottom tip="Kubernetes Version"> Kubernetes {kubernetesVersion || 'N/A'} </TipBottom>
            </Tag>
          );

          const configureStatusRegionA = region.configureStatus !== 'new' && (
            <Tag color={allowToAddDeployments ? 'green' : 'red'} style={floatingRight}>
              <TipBottom tip="Application Status"> {allowToAddDeployments ? 'Ready' : 'Do not allow to add new deployments'} </TipBottom>
            </Tag>
          );

          const configureStatusRegionB = region.configureStatus === 'new' && (
            <Tag color={'magenta'} style={floatingRight}>
              <TipBottom tip="Configuration is not finished"> Not Ready </TipBottom>
            </Tag>
          );

          return (
            <Col span={isCompactView ? 8 : 24} key={region.id} style={spaceWidth}>
              <Link to={`/clusters/${region.id}/overview`}>
                <Card
                  data-cy={region.id}
                  size="small"
                  type="inner"
                  bordered={false}
                  title={name}
                  extra={
                    <Space direction="horizontal">
                      <Tag> {region.id} </Tag>
                      <TipBottomRight tip={`Cloud Service Provider:  ${(cloudProvider || '').toUpperCase()}`}>
                        <Image src={servicesImage} alt={cloudProvider} preview={false} height={20} style={shadowStyle} />
                      </TipBottomRight>
                    </Space>
                  }
                >
                  <Text strong>
                    {publicRegion}
                    {region.regionName && (
                      <Tag color="blue" style={floatingLeft}>
                        <TipBottom tip="Region"> {region.regionName} </TipBottom>
                      </Tag>
                    )}
                    {clusterTags}
                    {configureStatusRegionA}
                    {configureStatusRegionB}
                  </Text>
                </Card>
              </Link>
            </Col>
          );
        })}
      </Row>
      <Text />
    </Space>
  );
};
