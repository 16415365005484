import { Button, Form, Input, InputNumber, Select } from 'antd';
import { buttonBorder } from 'utils/styles';
import gql from 'graphql-tag';
import { authService } from 'services/auth.service';
import { iJobModel } from 'shared/deployment';
import { DEFAULT_CLUSTER_VERSION, k8sVersionsList } from 'shared/k8sVersions';

export const Tf_AwsEks_JobEditForm = (props: { editJob: iJobModel; regionId: number; onFinish?: any }) => {
  const createOrUpdateJob = async (values: any) => {
    try {
      let editJob = props.editJob;
      if (!editJob?.id) {
        const res = await authService.getApolloClient().query({
          query: gql`
            mutation JobsController_newJob($regionId: Int!) {
              JobsController_newJob(regionId: $regionId) {
                id
                status
                region
                createdAt
                updatedAt
                variables
              }
            }
          `,
          variables: { regionId: Number(props.regionId) },
        });

        editJob = res.data.JobsController_newJob;
      }
      await authService.getApolloClient().query({
        query: gql`
          mutation JobsController_updateJob($jobId: Int!, $variables: JSON!) {
            JobsController_updateJob(jobId: $jobId, variables: $variables) {
              id
              status
              region
              createdAt
              updatedAt
              variables
              variables
            }
          }
        `,
        variables: { jobId: editJob.id || 0, variables: values },
      });
      await authService.getApolloClient().query({
        query: gql`
          mutation JobsController_runJob($jobId: Int!) {
            JobsController_runJob(jobId: $jobId) {
              id
            }
          }
        `,
        variables: { jobId: editJob.id },
      });
      props?.onFinish();
    } catch (e) {
      console.log(`runJobFunction: ${e}`);
      debugger;
    }
  };

  return (
    <Form
      layout="horizontal"
      labelCol={{ flex: '220px' }}
      labelWrap={true}
      style={{ maxWidth: 480 }}
      onFinish={async value => {
        try {
          await createOrUpdateJob(value);
        } catch (e) {
          console.log(e);
        }
      }}
      initialValues={{
        max_capacity: props.editJob?.variables?.max_capacity || 8,
        min_capacity: props.editJob?.variables?.min_capacity || 2,
        desired_capacity: props.editJob?.variables?.desired_capacity || 2,
        instance_types: props.editJob?.variables?.instance_types || 'm4.xlarge',
        cluster_version: props.editJob?.variables?.cluster_version || DEFAULT_CLUSTER_VERSION,
        disk_size: props.editJob?.variables?.disk_size || 80,
      }}
    >
      <Form.Item name="max_capacity" label="Max capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="min_capacity" label="Min capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="desired_capacity" label="Desired capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="instance_types" label="Instance types" help={`comma separated list`}>
        <Input style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="cluster_version" label="Kubernates version">
        <Select>
          {k8sVersionsList.map(version => {
            return <Select.Option value={version}>{version}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item name="disk_size" label="Disk size">
        <InputNumber style={{ width: `100%` }} suffix={`GB`} />
      </Form.Item>

      <Button type="primary" htmlType="submit" style={buttonBorder}>
        {/** @todo - Add popconfirm to confirm the creation of the cluster - Add a text note about the cost of the cluster - Call JobsController_newClusterPlan mutation if user agrees to create the cluster */}
        Save & Continue
      </Button>
    </Form>
  );
};
