import { UserNoPermissions } from 'components/Permissions/Permissions';
import { PROJECT_NAME } from 'interface/common';
import { TitleUIRow } from 'layout/TitleUI';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { Button, Image, Result, Space, Typography } from 'antd';
import { buttonWidth, noResultMargin } from 'utils/styles';
import { useUser } from 'utils/common';
import { TipBottom } from '../Tooltip/Tooltip';
import { Center, InfoApplication } from '../BigOptions/BigOptions';
import { NewClusterOptions } from 'components/Clusters/new-cluster/NewClusterPage';

const { Title, Text, Link } = Typography;

const noImage = <Image src="branding/landingPageImage.svg" alt="Instruction Page" preview={false} />;

const noTitle = <Title level={3}> Welcome to {PROJECT_NAME}! </Title>;

const createButton = (title: string) => {
  const vowelText = /^[AEIOUaeiou]/.test(title) ? 'an' : 'a';
  return (
    <TipBottom tip={`Click here to create ${vowelText} ${title}`}>
      <Button type="primary" style={buttonWidth}>
        {`To create ${vowelText} ${title} get started here`}
      </Button>
    </TipBottom>
  );
};

const InstructionText = ({ primaryText, secondaryText }) => (
  <Space direction="vertical">
    <Text> {primaryText} </Text>
    <Text> {secondaryText} </Text>
  </Space>
);

const NewButton = ({ button }) => button;

const createNew = (title, noText, createButtonElement) => (
  <>
    <TitleUIRow title={`Create a new ${title}`} />
    <Result style={noResultMargin} icon={noImage} title={noTitle} subTitle={noText} extra={<NewButton button={createButtonElement} />} />
  </>
);

const noDashboardText = (
  <InstructionText
    primaryText="Your space looks a little empty. Are you ready to deploy your first application?"
    secondaryText="We'll guide you along the process of adding your first project through to releasing your first application in few easy steps."
  />
);

const noProjectText = (
  <InstructionText
    primaryText="Your template list seems empty. Are you ready to kickstart your first template?"
    secondaryText="Let us guide you through the process of creating your first template and releasing your initial application in just few simple steps."
  />
);

const noClusterText = (
  <InstructionText
    primaryText="Looks like you haven't set up clusters yet. Ready to start?"
    secondaryText="We'll help you set up your first cluster and deploy your applications in just few easy steps."
  />
);

export const NoClusters = () => createNew('Cluster', noClusterText, <Link href="/#/cluster/new"> {createButton('Cluster')} </Link>);

export const NoApplication = () => {
  const user = useUser();

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;
  }

  return (
    <Center>
      <Space direction="vertical">
        <InfoApplication />
        <NewClusterOptions />
      </Space>
    </Center>
  );
};
