import gql from 'graphql-tag';
import Markdown from 'react-markdown';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from '../../../utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { useUser } from 'utils/common';
import { iMyUserData } from 'shared/user';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { PROJECT_NAME } from 'interface/common';
import { Col, Skeleton, Tag, Button, Modal, Row, Menu, Typography, Space, Alert, Flex } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { buttonWidth, internalStatusTag, primaryBorderColor, siderStyle, spaceWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';

const { Text } = Typography;
const { Item } = Menu;

export function isPluginInstalled(region: iRegionModel, pluginName: string) {
  return region.plugins.includes(pluginName);
}

export interface iOnePluginPageProps {
  region: iRegionModel;
  refetchRegionData: () => void;
}

export default function OnePluginPage(props: iOnePluginPageProps) {
  const user: iMyUserData = useUser();
  const params: any = useParams();
  const pluginId = Number(params.subTabName);
  const region = props.region;

  const history = useHistory();
  const { loading, error, data, refetch } = useAuthedQuery(
    gql`
      query PluginsController_getPluginData($pluginId: Int!, $regionId: Int!) {
        PluginsController_getPluginsForCuster(regionId: $regionId) {
          id
          name
          title
          description
          logo
          tags
          fullDescription
          installTypeDeployment
          installTypeManual
        }
        PluginsController_getPluginData(pluginId: $pluginId) {
          id
          name
          title
          description
          logo
          tags
          fullDescription
          installTypeDeployment
          installTypeManual
        }
        PluginsController_getPluginDeployments(pluginId: $pluginId, regionId: $regionId) {
          deployments {
            id
            name
          }
        }
      }
    `,
    { variables: { pluginId: pluginId, regionId: Number(region.id) } },
  );

  const [createDeploymentAddon, newDeployment] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_createDeploymentAddon($pluginId: Int!, $regionId: Int!) {
      DeploymentsController_createDeploymentAddon(pluginId: $pluginId, regionId: $regionId) {
        id
      }
    }
  `);

  const [markAsInstalled] = useAuthedMutationWithNotification(gql`
    mutation PluginsController_markAsInstalled($pluginId: Int!, $regionId: Int!) {
      PluginsController_markAsInstalled(pluginId: $pluginId, regionId: $regionId)
    }
  `);

  const [markAsNotInstalled] = useAuthedMutationWithNotification(gql`
    mutation PluginsController_markAsNotInstalled($pluginId: Int!, $regionId: Int!) {
      PluginsController_markAsNotInstalled(pluginId: $pluginId, regionId: $regionId)
    }
  `);

  const newDeploymentId = newDeployment?.data?.DeploymentsController_createDeploymentAddon?.id;
  const plugin = data?.PluginsController_getPluginData;
  const allPlugins = data?.PluginsController_getPluginsForCuster;

  if (newDeploymentId) {
    history.push(`/app/${newDeploymentId}/status/overview`);
  }
  if (loading || !user || !plugin || !allPlugins) {
    return <Skeleton active={true} loading={true} />;
  }

  const installedDeployments = data?.PluginsController_getPluginDeployments?.deployments;
  const isInstalled = isPluginInstalled(region, plugin.name);
  const isOwnCluster = region.tenant === user.tenant;

  const handle_InstallExtension = () => {
    Modal.confirm({
      title: 'Do you want to install this extension?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction="vertical">
          <Text> To install this plugin, you need to create one new application in your cluster. </Text>
          <Text> We have already prepared a settings template for this application for you. </Text>
          <Text> In the following steps you can configure additional settings. </Text>
        </Space>
      ),
      okText: 'Next',
      onOk: async () => {
        await createDeploymentAddon({ variables: { pluginId: Number(plugin.id), regionId: Number(props.region.id) } });
      },
    });
  };

  const handle_MarkNotInstalled = () => {
    Modal.confirm({
      title: 'This plugin marked as installed. If it is not installed you can mark it as not installed.',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Text>
          This action will not remove the extension from the cluster. But the {PROJECT_NAME} will not use it until you mark this extension as
          installed.
        </Text>
      ),
      okText: 'Mark as not installed',
      onOk: async () => {
        await markAsNotInstalled({ variables: { pluginId: Number(plugin.id), regionId: Number(region.id) } });
        await props.refetchRegionData();
        await refetch();
      },
    });
  };

  const handle_MarkInstalled = () => {
    Modal.confirm({
      title: 'Mark extension as installed?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction="vertical">
          <Text>
            This action will not install anything into the cluster. But the application portal will not work as if this extension was already
            installed in the cluster and configured correctly.
          </Text>
          <Text> This makes sense if you have already installed and configured this module yourself. </Text>
        </Space>
      ),
      okText: 'Mark as installed',
      onOk: async () => {
        await markAsInstalled({ variables: { pluginId: Number(plugin.id), regionId: Number(region.id) } });
        await props.refetchRegionData();
        await refetch();
      },
    });
  };

  const logoTags = (
    <Flex justify={'flex-end'}>
      <Space direction="vertical">
        <Text />
        <Space direction="horizontal">
          {plugin.logo && <LogoAvatar logo={plugin.logo} name={plugin.title} />}
          {isInstalled ? (
            <Tag color="green" style={internalStatusTag}>
              Installed
            </Tag>
          ) : null}
          {plugin.tags && plugin.tags.map(tag => <Tag style={internalStatusTag}> {tag} </Tag>)}
        </Space>
      </Space>
    </Flex>
  );

  const installDeployment = (
    <>
      {plugin.installTypeDeployment ? (
        installedDeployments?.length > 0 ? (
          <div>
            In this Cluster already installed this plugin in the following Deployments:
            <ul>
              {installedDeployments.map(deployment => (
                <li>
                  <Link to={`/app/${deployment?.id}/status/overview`}> {deployment?.name} </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          isOwnCluster && (
            <Alert
              showIcon
              type="info"
              message="To Install this Plugin, you can create one new application in your cluster."
              action={
                <Button type="primary" onClick={handle_InstallExtension} style={buttonWidth}>
                  Install
                </Button>
              }
            />
          )
        )
      ) : null}
    </>
  );

  const markInstall = (
    <>
      {isOwnCluster && (
        <Alert
          showIcon
          type="info"
          message={
            isInstalled
              ? 'This plugin is marked as Installed. If it is not Installed you can mark it as not Installed.'
              : 'This plugin marked as not Installed. If you Installed it manually you can mark it as Installed.'
          }
          action={
            isInstalled ? (
              <Button onClick={handle_MarkNotInstalled} style={buttonWidth}>
                Mark as not installed
              </Button>
            ) : (
              <Button onClick={handle_MarkInstalled}> Mark as installed </Button>
            )
          }
          style={spaceWidth}
        />
      )}
    </>
  );

  return (
    <Row gutter={[0, 0]} wrap={false}>
      <Col flex={'250px'} style={{ position: 'relative', ...primaryBorderColor, left: -15, minHeight: 'calc(100vh - 115px)' }}>
        <Menu defaultSelectedKeys={[`${pluginId}`]} defaultOpenKeys={[`${pluginId}`]} mode="inline" style={siderStyle}>
          {[...allPlugins]
            .sort((a, b) => (region.plugins.includes(a.name) ? -1 : region.plugins.includes(b.name) ? 1 : 0))
            .map(elem => (
              <Item key={`${elem.id}`} onClick={(e: any) => history.push(`/clusters/${region.id}/integrations/${elem.id}`)}>
                {region.plugins.includes(elem.name) ? (
                  <Tag color="green" bordered={false}>
                    <CheckOutlined />
                  </Tag>
                ) : null}
                {elem.name}
              </Item>
            ))}
        </Menu>
      </Col>
      <Col flex="auto">
        <Space direction="vertical" style={spaceWidth}>
          {logoTags}
          <Markdown>{plugin.fullDescription || plugin.description}</Markdown>
          {markInstall}
          {!isOwnCluster && <Alert showIcon type="info" message="This is a shared Cluster, you can not change the settings" />}
          {installDeployment}
          <Text />
        </Space>
      </Col>
    </Row>
  );
}
