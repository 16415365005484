import { Skeleton, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { AWSPriceForDeployment } from '../monitoring/PriceCharts/AWSPriceForDeployment';
import { OpenCostPriceForDeployment } from '../monitoring/PriceCharts/OpenCostPriceForDeployment';
import { MonitoringOfServices } from '../monitoring/MonitoringOfServices/MonitoringOfServices';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';

const { TabPane } = Tabs;

export default function DeploymentMonitoringPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  if (!tabName) history.push(`/app/${id}/status/services`);
  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (loading || !deployment) return <Skeleton active={true} loading={true} />;
  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/advanced/details`);
    return <Skeleton active={true} loading={true} />;
  }

  const handleTabClick = (key: any) => history.push(`/app/${id}/monitoring/${key}`);

  const tabsData = () => {
    const tabConfig = [
      { tab: 'Services', key: 'services', component: <MonitoringOfServices deployment={deployment} /> },
      { tab: 'OpenCost', key: 'price-opencost', component: <OpenCostPriceForDeployment deployment={deployment} /> },
      { tab: 'AWS', key: 'price-aws', component: <AWSPriceForDeployment deployment={deployment} /> },
      // { tab: 'Nodes', key: 'nodes', component: <PodsOnNodes deployment={deployment} /> },
    ];

    return (
      <Tabs defaultActiveKey={tabName || 'services'} onChange={handleTabClick}>
        {tabConfig.map(({ tab, key, component }) => (
          <TabPane tab={tab} key={key}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    );
  };

  return (
    <>
      <TitleUIRow title={`Application monitoring`} />
      <DeploymentWidgetsRow deployment={deployment} />
      {tabsData()}
    </>
  );
}
