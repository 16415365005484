import gql from 'graphql-tag';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../interface/common';
import { useAuthedMutationWithNotification } from '../../../utils/qlAuth';
import { iJobModel, iRegionModel } from 'shared/deployment';
import { ShowJobLogsBtn } from './JobLogs';
import { Button, Popconfirm, Space } from 'antd';
import { TipTopLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface ButtonJobControlProps {
  region: iRegionModel;
  showJobList: any;
  refetchJobList: any;
  clusterAreReady: boolean;
}

export const ButtonJobControl = (props: ButtonJobControlProps) => {
  const [newJobFunction, newJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_newJob($regionId: Int!) {
      JobsController_newJob(regionId: $regionId) {
        id
        status
        region
        createdAt
        updatedAt
        variables
      }
    }
  `);

  const [destroyPlanJobFunction, destroyPlanJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_newDestroyPlanJob($regionId: Int!) {
      JobsController_newDestroyPlanJob(regionId: $regionId) {
        id
      }
    }
  `);

  const [ebsJobFunction, ebsJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallEBS_Job($regionId: Int!) {
      JobsController_InstallEBS_Job(regionId: $regionId) {
        id
      }
    }
  `);

  const [installKarpenter_Job, InstallKarpenter_JobId] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallKarpenter_Job($regionId: Int!) {
      JobsController_InstallKarpenter_Job(regionId: $regionId) {
        id
      }
    }
  `);

  const [installApiAgent_Job, installApiAgent_JobId] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallApiAgent_Job($regionId: Int!) {
      JobsController_InstallApiAgent_Job(regionId: $regionId) {
        id
      }
    }
  `);

  useEffect(() => {
    props.refetchJobList();
  }, [newJobRes, destroyPlanJobRes, ebsJobRes, InstallKarpenter_JobId, installApiAgent_JobId]);

  let lastPlanJob: iJobModel = (props.showJobList || []).find((job: iJobModel) => job.tfMode === 'plan' && job.status === `finished`);
  let planIsDone = lastPlanJob?.id;
  let lastDestroyPlanJob: iJobModel = (props.showJobList || []).find((job: iJobModel) => job.tfMode === 'destroy-plan' && job.status === `finished`);

  const clusterPlan = () => {
    const clusterPlan = props.clusterAreReady;
    return (
      <TipTopLeft
        tip={
          clusterPlan
            ? 'This will create a new plan job. It will create a new plan job and run the terraform plan command. It will not apply any changes to your infrastructure.'
            : 'This will create a new plan job. It will create a new plan job and run the terraform plan command. It will not apply any changes to your infrastructure. This plan is for Cluster creation.'
        }
      >
        <Button
          onClick={async () => await newJobFunction({ variables: { regionId: Number(props.region.id) } })}
          type={!planIsDone ? 'primary' : 'default'}
        >
          {clusterPlan ? `Cluster modification plan` : `Cluster creation plan`}
        </Button>
      </TipTopLeft>
    );
  };

  const popDestroy = () => {
    const popDestroyOnConfirm = async e => {
      try {
        await destroyPlanJobFunction({ variables: { regionId: props.region.id } });
      } catch (e) {
        console.error(`Error in destroyPlanJobFunction`, e);
      }
    };
    return (
      <Popconfirm
        title="Cluster destroy plan"
        description={
          <>
            <ul>
              <li> This will create a destroy plan. </li>
              <li> It will create a new plan job and run the terraform plan command. </li>
              <li> It will not apply any changes to your infrastructure. </li>
            </ul>
          </>
        }
        onConfirm={popDestroyOnConfirm}
        okText="Continue"
        cancelText="Cancel"
      >
        <Button hidden={!props.clusterAreReady} type="default">
          Destroy plan
        </Button>
      </Popconfirm>
    );
  };

  const popInstall = () => {
    const initializeEBS = props.region.plugins.includes('AWS-EBS-driver');
    const popInstallDescription = (
      <ul>
        <li> This will install EBS addon into you cluster </li>
        <li>
          <Link to={`/clusters/${props.region.id}/integrations/10`}> Read more about EBS </Link>
        </li>
      </ul>
    );
    const popInstallOnConfirm = async e => {
      try {
        await ebsJobFunction({ variables: { regionId: Number(props.region.id) } });
      } catch (e) {
        console.error(`Error in createEbs`, e);
      }
    };
    return (
      <Popconfirm
        title="Install Amazon Elastic Block Store (Amazon EBS)"
        description={popInstallDescription}
        onConfirm={popInstallOnConfirm}
        okText="Continue"
        cancelText="Cancel"
      >
        <Button hidden={!props.clusterAreReady || props.region.cloudProvider !== 'aws'} type={initializeEBS ? 'default' : 'primary'}>
          {initializeEBS ? 'Reinstall' : 'Install'} EBS
        </Button>
      </Popconfirm>
    );
  };

  const popInstalled = () => {
    const initializeKarpenter = props.region.plugins.includes('Karpenter');
    const popInstalledDescription = (
      <ul>
        <li> This will install Karpenter into you cluster </li>
        <li>
          <Link to={`/clusters/${props.region.id}/integrations/5`}> Read more about Karpenter </Link>
        </li>
      </ul>
    );
    const popInstalledOnConfirm = async e => {
      try {
        await installKarpenter_Job({ variables: { regionId: Number(props.region.id) } });
      } catch (e) {
        console.error(`Error in installKarpenter_Job`, e);
      }
    };
    return (
      <Popconfirm
        title="Install Karpenter"
        description={popInstalledDescription}
        onConfirm={popInstalledOnConfirm}
        okText="Continue"
        cancelText="Cancel"
      >
        <Button hidden={!props.clusterAreReady || props.region.cloudProvider !== 'aws'} type={initializeKarpenter ? 'default' : 'primary'}>
          {initializeKarpenter ? 'Reinstall' : 'Install'} Karpenter
        </Button>
      </Popconfirm>
    );
  };

  const popReInstallDescription = (
    <>
      <ul>
        <li> Update {PROJECT_NAME} agent </li>
      </ul>
    </>
  );
  const popReInstallOnConfirm = async e => {
    try {
      await installApiAgent_Job({ variables: { regionId: Number(props.region.id) } });
    } catch (e) {
      console.error(`Error in installApiAgent_Job`, e);
    }
  };
  const agentName = `Reinstall ${PROJECT_NAME} agent`;

  const planControl = () => lastPlanJob && <ShowJobLogsBtn job={lastPlanJob} type={props.clusterAreReady ? `default` : `primary`} title={`Apply`} />;

  const destroyControl = () => lastDestroyPlanJob && <ShowJobLogsBtn job={lastDestroyPlanJob} type={`primary`} title={`Destroy`} />;

  return (
    <Space direction="horizontal">
      {clusterPlan()}
      {planControl()}
      {popDestroy()}
      {destroyControl()}
      {popInstall()}
      {popInstalled()}
      {
        <Popconfirm title={agentName} description={popReInstallDescription} onConfirm={popReInstallOnConfirm} okText="Continue" cancelText="Cancel">
          <Button hidden={!props.clusterAreReady} type="default">
            {agentName}
          </Button>
        </Popconfirm>
      }
    </Space>
  );
};
