import './Authentication.scss';
import history from '../../history';
import { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { handleInputChange, isMainDomain, useApiQuery } from '../../utils/common';
import { Typography, Button, Input, notification, Skeleton, Space, Form } from 'antd';
import { ArrowRightOutlined, GithubFilled, LoginOutlined } from '@ant-design/icons';
import { formInput, formButton, buttonBorder } from 'utils/styles';
import { capitalLetter } from 'utils/nameFormat';
import { AuthLogin } from 'components/SharedComponents/AuthComp/AuthComp';
import NotFoundPage from 'components/SharedComponents/NotFound/NotFound';

const { Text } = Typography;
const { Password } = Input;

export const FindTenantForm = () => {
  const [email, setEmail] = useState('');
  const [tenants, setTenants] = useState([]);
  const [lastEmail, setLastEmail] = useState(undefined);
  const [showSignUp, setShowSignUp] = useState(false);

  const onTenantsRequest = async (email: any) => {
    const { error, status, data } = await authService.tenants(email);
    if (error) {
      return notification.error({ key: 'Login', message: `status ${status}`, description: data.message });
    }
    setLastEmail(email);
    if (data.length === 1) {
      window.location.replace(`https://${data[0]}.${window.location.host.replace('auth.', '')}/#/login/email/${encodeURIComponent(email)}`);
    }
    if (data.length === 0) {
      setShowSignUp(true);
      notification.warning({ key: 'Login', message: 'Your email does not exist.' });
    }
    setTenants(data);
  };

  const emailButtonLogin = () => (
    <Button type="primary" disabled={lastEmail === email} onClick={() => onTenantsRequest(email)} style={formButton}>
      Login
    </Button>
  );

  const findTenantFormData = () => {
    const signUpLink = (
      <Text strong>
        <a href="/signup"> Sign up? </a>
      </Text>
    );
    const handleFormSubmit = e => {
      e.preventDefault();
      onTenantsRequest(email);
    };
    const handleFormChange = handleInputChange(setEmail);
    const tenantForm = () => (
      <AuthLogin>
        <form onSubmit={handleFormSubmit}>
          <Space direction="vertical" style={formInput}>
            <Text strong> Email </Text>
            <Input
              placeholder="Enter email here"
              value={email}
              onChange={handleFormChange}
              onPressEnter={e => handleFormSubmit(e)}
              addonAfter={showSignUp && signUpLink}
            />
            {lastEmail !== email || tenants.length == 0 ? emailButtonLogin() : null}
            {!(lastEmail !== email)
              ? tenants.map(tenant => {
                  const handleOpenTenant = () => {
                    window.location.replace(
                      `https://${tenant}.${window.location.host.replace('auth.', '')}/#/login/email/${encodeURIComponent(email)}`,
                    );
                  };
                  return (
                    <Space direction="horizontal">
                      <Button type="primary" onClick={handleOpenTenant} style={buttonBorder}>
                        Open Tenant
                      </Button>
                      {capitalLetter(tenant)}
                    </Space>
                  );
                })
              : null}
          </Space>
        </form>
      </AuthLogin>
    );
    return tenantForm();
  };

  return findTenantFormData();
};

const onLoginRequest = async ({ email, password }: any) => {
  notification.info({ key: 'Login', description: 'Please wait a second...', message: '' });
  const { error, status, data } = await authService.login({ email, password });
  error
    ? notification.error({ key: 'Login', message: `status ${status}`, description: data.message })
    : (window.location.host === 'localhost:3000' &&
        ((window.localStorage['local_host_login'] = email), (window.localStorage['local_host_password'] = password)),
      window.location.reload());
};

export default function Login() {
  // @ts-ignore
  let { email, oldPath } = useParams();
  const [login, setLogin] = useState(decodeURIComponent(email || ''));
  const [password, setPassword] = useState('');
  const [authStatus, setAuthStatus] = useState(undefined);
  const [authTypeInfo, error, loading] = useApiQuery(() => authService.authTypeInfo());
  const savedLogin = window.localStorage['local_host_login'];
  let gitLink = '/api/github/oauth/authorize';

  if (!loading && authStatus === 'no') {
    if (authTypeInfo?.useSso) {
      window.location.replace(`${window.location.origin}/api/auth/sso`);
    }
  }

  useEffect(() => {
    const checkUser = async () => {
      const { error } = await authService.getSelfInfo();
      !error ? (oldPath ? history.push(decodeURIComponent(oldPath).replace('#/', '')) : history.push('/')) : setAuthStatus('no');
    };
    checkUser();
  }, []);

  if (window.location.host === 'localhost:3000' && !login) {
    if (savedLogin) {
      setLogin(savedLogin);
      setPassword(window.localStorage['local_host_password']);
    }
  }

  if (window.location.hostname === 'localhost') {
    gitLink = 'http://localhost:4001/api/github/oauth/authorize';
  }

  if (loading || authTypeInfo?.useSso) {
    return <Skeleton active={true} loading={true} />;
  }

  if (error) {
    return <NotFoundPage title="Domain does not exist" navigateTo="Main Domain" />; // Replace ErrorComponent with the component you want to render when there's an error
  }

  if (isMainDomain()) {
    return <FindTenantForm />;
  }

  const handleOnKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onLoginRequest({ email: login, password });
    }
  };
  return (
    <AuthLogin>
      <Form onKeyDown={handleOnKeyDown}>
        <Space direction="vertical" style={formInput}>
          <Fragment key={`login`}>
            <Text strong> {`Username`} </Text>
            <Input placeholder={`Enter username here`} data-cy={`login`} value={login} onChange={handleInputChange(setLogin)} />
          </Fragment>
          <Fragment key={`password`}>
            <Text strong> {`Password`} </Text>
            <Password placeholder={`Enter password here`} data-cy={`password`} value={password} onChange={handleInputChange(setPassword)} />
          </Fragment>

          <Text strong>
            <Link to="/reset-password">
              <Space direction="horizontal">
                Forgot password? <ArrowRightOutlined />
              </Space>
            </Link>
          </Text>

          <Space direction="horizontal">
            <Button type="primary" data-cy="loginSubmit" onClick={() => onLoginRequest({ email: login, password })} style={formButton}>
              Sign In <LoginOutlined />
            </Button>
            <a href={gitLink} rel="nofollow">
              <Button type="primary" style={formButton}>
                Signin with GitHub <GithubFilled />
              </Button>
            </a>
          </Space>
        </Space>
      </Form>
    </AuthLogin>
  );
}
