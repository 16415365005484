import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Tabs, Tag } from 'antd';
import { iDeployment } from 'shared/deployment';
import { iProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { buttonBorder, buttonSize } from 'utils/styles';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { useState } from 'react';
import { ProjectContainerStorage } from 'components/Projects/ProjectContainerStorage/ProjectContainerStorage';

interface IImagePullSecrets {
  deployment: iDeployment;
  imagePullSecrets: { name: string; namespace: string }[];
  syncVarsContext: iProjectDeploymentSyncVarsContext;
  onChange: (secrets: { name: string; namespace: string }[]) => void;
}

/**
 * UI for managing image pull secrets in deployment
 * @param props
 * @returns
 */
export const ImagePullSecrets = (props: IImagePullSecrets) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [_, setIsRefetched] = useState(false);
  const { loading, error, data, refetch } = useAuthedQuery(
    gql`
      query getContainerStorageFormConfig($projectId: Int!) {
        getContainerStorageConfig(projectId: $projectId) {
          registry {
            type
            name
            args
          }
        }
      }
    `,
    { variables: { projectId: props.deployment.ProjectModel.id } },
  );

  const secretList = data?.getContainerStorageConfig?.registry.map(({ name }) => {
    return { label: name, value: name };
  });

  const refetchSecrets = async () => {
    await refetch();
    setIsRefetched(true);
  };

  const handleOnClick = () => {
    setModalVisible(true);
  };

  const tags = props.imagePullSecrets?.length;

  const handleSubmit = async e => {
    props.onChange(e.selectedOptions);
    setModalVisible(false);
  };

  const renderModalData = () => {
    const items = [
      {
        key: '1',
        label: 'Select secrets',
        children: (
          <Form onFinish={handleSubmit} initialValues={{ selectedOptions: props.imagePullSecrets }}>
            <Form.Item label="Select Secrets  " name="selectedOptions">
              <Select
                mode="multiple"
                placeholder="Select Secrets"
                onChange={() => console.log('changed')}
                style={{ width: '100%' }}
                defaultValue={props.imagePullSecrets}
              >
                {secretList &&
                  secretList.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button key="back" onClick={() => setModalVisible(false)} style={buttonBorder}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" style={buttonBorder}>
                Save
              </Button>
            </Form.Item>
          </Form>
        ),
      },
      {
        key: '2',
        label: 'Create a Secret',
        children: (
          <>
            <ProjectContainerStorage deployment={props.deployment} refetchSecrets={refetchSecrets} />
          </>
        ),
      },
    ];
    return <Tabs defaultActiveKey="1" items={items} />;
  };

  return (
    <>
      <Space direction="horizontal">
        <Button icon={<EditOutlined />} onClick={handleOnClick} style={buttonSize}>
          Manage secrets
        </Button>
        <Tag color={tags ? 'green' : 'red'}> {tags ? 'Configured' : 'Not configured'} </Tag>
      </Space>
      <Modal
        title="Image Pull Secrets"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => setModalVisible(true)}
        width="50%"
        footer={null}
      >
        {renderModalData()}
      </Modal>
    </>
  );
};
