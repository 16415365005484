import { gql } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { setContainerStorageConfig } from 'queries/queries';
import { useAuthedMutation, useAuthedQuery } from 'utils/qlAuth';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { NullSpaces } from 'components/SharedComponents/NullSpaces/NullSpaces';
import { Alert, Skeleton, notification, Button, Collapse, Form, Input, Space, Radio, Typography, Image } from 'antd';
import { DeleteFilled, KeyOutlined } from '@ant-design/icons';
import { spaceWidth, buttonWidth, buttonBorder } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { TipRight, TipTopLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { DeployButton } from 'components/Deployments/Setting/new-deployment/DeployPage';
import { SecretsTabHelAlert } from './SecretsTabHelAlert';

export interface IProjectContainerStorageProps {
  deployment: iDeployment;
  refetchSecrets?: () => void;
}

export interface IOneSecretProps {
  index: number;
  subField: any;
  subOpt: any;
  item?: any;
  form: any;
}

const { Title, Text } = Typography;
const { Item, List } = Form;
const { Password } = Input;
const { Panel } = Collapse;
const { Group } = Radio;

export const OneSecret = (props: IOneSecretProps) => {
  const subField = props.subField;
  const [secretType, set_secretType] = useState(props.item?.type || 'password');

  useEffect(() => {
    props.item?.type ? set_secretType(props.item.type) : null;
  }, [props.item?.type]);

  const renderRadioButtons = () => {
    const radioOptions = [
      { value: 'password', tooltip: 'Login and Password', icon: <KeyOutlined /> },
      { value: 'AWS ECR', tooltip: 'AWS ECR', icon: <Image src="branding/logoAWS.svg" preview={false} height={25} /> },
      { value: 'quay.io', tooltip: 'Quay.io', icon: <Image src="branding/logoQuay.svg" preview={false} height={20} /> },
      { value: 'docker.io', tooltip: 'Docker Hub', icon: <Image src="branding/logoDocker.svg" preview={false} height={25} /> },
    ];
    return radioOptions.map(option => (
      <Radio.Button key={option.value} value={option.value}>
        <TipTopLeft tip={option.tooltip}> {option.icon} </TipTopLeft>
      </Radio.Button>
    ));
  };

  const secretTypeConfig = () => {
    const renderLoginForm = (loginItems: any[]) =>
      loginItems.map((item, index) => (
        <Item key={index} name={[subField.name, item.name]} label={<Text> {item.label} </Text>}>
          {item.password ? <Password placeholder={item.placeholder} /> : <Input placeholder={item.placeholder} />}
        </Item>
      ));

    const generateLoginItems: any = (loginType: string) => {
      const passwordForm = [
        { name: 'args.user', label: `Username ${'\u00A0'.repeat(26)}` },
        { name: 'args.password', label: `Password ${'\u00A0'.repeat(27)}` },
        { name: 'args.server', label: `Server ${'\u00A0'.repeat(32)}` },
      ];
      const awsForm = [
        { name: 'args.AWS_ACCESS_KEY_ID', label: `AWS access key ID ${'\u00A0'.repeat(12)}` },
        { name: 'args.AWS_SECRET_ACCESS_KEY', label: `AWS secret access key ${'\u00A0'.repeat(6)}`, password: true },
        { name: 'args.region', label: `AWS Region with ECR ${'\u00A0'.repeat(7)}` },
        { name: 'args.ECR_URL', label: `AWS ECR URL ${'\u00A0'.repeat(20)}` },
      ];
      const quayForm = [
        { name: 'args.user', label: `Username ${'\u00A0'.repeat(26)}` },
        { name: 'args.password', label: `Robot Account ${'\u00A0'.repeat(18)}`, password: true },
      ];
      const dockerForm = [
        { name: 'args.user', label: `Username ${'\u00A0'.repeat(26)}` },
        { name: 'args.password', label: `Access Token ${'\u00A0'.repeat(21)}`, password: true },
      ];
      const loginItemConfig = { password: passwordForm, aws: awsForm, quay: quayForm, docker: dockerForm };
      return loginItemConfig[loginType].map((item: { label: any }) => ({ ...item, placeholder: `Enter your ${item.label}` }));
    };

    const passwordLogin = () => renderLoginForm(generateLoginItems('password'));
    const awsLogin = () => renderLoginForm(generateLoginItems('aws'));
    const quayLogin = () => renderLoginForm(generateLoginItems('quay'));
    const dockerLogin = () => renderLoginForm(generateLoginItems('docker'));

    const loginForms = { password: passwordLogin, 'AWS ECR': awsLogin, 'quay.io': quayLogin, 'docker.io': dockerLogin };

    return (
      secretType && (
        <Collapse size="small" accordion>
          <Panel key="1" header={<Text strong> Config for container storage provider </Text>}>
            {loginForms[secretType]?.()}
          </Panel>
        </Collapse>
      )
    );
  };

  return (
    <>
      <Space direction="vertical">
        <Text />
        <Text strong> Choose the Container Storage Provider Here: </Text>
        <Space direction="horizontal">
          <Space direction="vertical">
            <Text />
            <Item name={[subField.name, 'type']} label={``}>
              <Group size="large" defaultValue="password" onChange={e => set_secretType(e.target.value)}>
                {renderRadioButtons()}
              </Group>
            </Item>
          </Space>
          <Space>
            <TipRight tip="Delete Addition Variables Here">
              <Button type="link" size="large" danger>
                <DeleteFilled onClick={() => props.subOpt.remove(subField.name)} />
              </Button>
            </TipRight>
          </Space>
        </Space>
      </Space>
      <Item
        name={[subField.name, 'name']}
        label={<Text strong> Secret Name </Text>}
        rules={[{ message: 'Enter Your Secret Name Here', required: true }]}
      >
        <Input placeholder="Enter Your Secret Name Here" />
      </Item>
      {secretTypeConfig()}
    </>
  );
};

export const ProjectContainerStorage = (props: IProjectContainerStorageProps): ReactElement => {
  const [form] = Form.useForm();
  const { loading, error, data } = useAuthedQuery(
    gql`
      query getContainerStorageFormConfig($projectId: Int!) {
        getContainerStorageConfig(projectId: $projectId) {
          registry {
            type
            name
            args
          }
        }
      }
    `,
    { variables: { projectId: props.deployment.ProjectModel.id } },
  );
  const [mutateFunction, saveRes] = useAuthedMutation(setContainerStorageConfig);

  const registry: any[] = (data?.getContainerStorageConfig.registry || []).map((item: any) => {
    let res = { type: item.type || 'password', name: item.name };
    item.type === 'AWS ECR'
      ? ((res['args.ECR_URL'] = item?.args?.ECR_URL),
        (res['args.region'] = item?.args?.region),
        (res['args.AWS_SECRET_ACCESS_KEY'] = item?.args?.AWS_SECRET_ACCESS_KEY),
        (res['args.AWS_ACCESS_KEY_ID'] = item?.args?.AWS_ACCESS_KEY_ID))
      : ((res['args.user'] = item?.args?.user), (res['args.password'] = item?.args?.password), (res['args.server'] = item?.args?.server));
    return res;
  });

  useEffect(() => {
    data?.getContainerStorageConfig ? form.setFieldsValue({ registry: registry }) : null;
  }, [data]);

  if (error) {
    return (
      <Skeleton active={true} loading={true}>
        Error: {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || error) return <Skeleton active={true} loading={true} />;

  const handleSubmit = async (): Promise<boolean> => {
    const formData = form.getFieldsValue();
    const registry = formData.registry.map((item: any) => {
      let args = {};
      args =
        item.type === 'AWS ECR'
          ? {
              ECR_URL: item['args.ECR_URL'],
              region: item['args.region'],
              AWS_SECRET_ACCESS_KEY: item['args.AWS_SECRET_ACCESS_KEY'],
              AWS_ACCESS_KEY_ID: item['args.AWS_ACCESS_KEY_ID'],
            }
          : { user: item['args.user'], password: item['args.password'], server: item['args.server'] };
      return { type: item.type || 'password', name: item.name, args: args };
    });
    await mutateFunction({ variables: { config: { form: { registry } }, projectId: props.deployment.ProjectModel.id } });
    if (saveRes.error) {
      notification.error({ message: `Error - ${saveRes.error}` });
      return false;
    }
    notification.success({ message: `Ready` });
    if (props.refetchSecrets) await props.refetchSecrets();
    return true;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Title level={5}> Pull Secrets Configuration </Title>
      <SecretsTabHelAlert />
      <Form onFinish={handleSubmit} form={form} name="dynamic_form_complex" autoComplete="off" initialValues={{ registry: registry }}>
        <List name="registry">
          {(subFields, subOpt) => (
            <>
              {subFields.map((subField, index) => {
                const itemArr = form.getFieldsValue()?.registry;
                const item = itemArr ? itemArr[subField.key] : registry[subField.key];
                return <OneSecret form={form} index={index} subField={subField} subOpt={subOpt} item={item} />;
              })}
              <Space direction="vertical">
                <Text />
                <Button type="primary" onClick={() => subOpt.add()} style={buttonWidth}>
                  Add Image Pull Secret
                </Button>
                {!props.refetchSecrets ? <NullSpaces /> : null}
              </Space>
            </>
          )}
        </List>
        <Item>
          {props.refetchSecrets ? (
            <Button type="primary" htmlType="submit" style={{ ...buttonBorder, marginTop: '16px' }}>
              Save
            </Button>
          ) : (
            <BottomButtons>
              <Button type="primary" htmlType="submit" style={buttonBorder}>
                Save
              </Button>
              <DeployButton deployment={props.deployment} beforeDeploy={handleSubmit} />
            </BottomButtons>
          )}
        </Item>
      </Form>
    </Space>
  );
};
