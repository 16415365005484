import gql from 'graphql-tag';
import moment from 'moment';
import { DiffEditor } from '@monaco-editor/react';
import { SetStateAction, useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { iSpecsRevision } from 'shared/SpecsRevisions';
import { arrToYamlString } from 'shared/yaml';
import { buttonBorder, contentJustified, revisionWidth, spaceWidth } from 'utils/styles';
import { useAuthedMutation, useAuthedQuery } from 'utils/qlAuth';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';
import { Alert, Button, Flex, Input, Modal, Popconfirm, Select, Skeleton, Space, Timeline, Typography } from 'antd';
import { authService } from 'services/auth.service';
import { DownOutlined } from '@ant-design/icons';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface iNewRevisionForm {
  deployment: iDeployment;
  onNewRevision: (revision: any) => void;
  beforeDeploy?: (iDeployButton: any) => boolean | Promise<boolean>;
  btnText?: string;
  applyLater?: boolean;
  disabled?: boolean;
}

interface iNewRevision {
  target: { value: SetStateAction<string> };
}

interface iSpecsRevisions {
  deployment: iDeployment;
}

interface isetRevisionId {
  (value: SetStateAction<number>): void;
  (value: SetStateAction<number>): void;
  (arg0: number): void;
}

const { Text } = Typography;
const { TextArea } = Input;

export const NewRevisionForm = ({ deployment, beforeDeploy, applyLater, disabled, btnText }: iNewRevisionForm) => {
  const [revisionName, setRevisionName] = useState('');
  const [revisionDescription, setRevisionDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const hasChangesForApply = deployment?.hasChangesForApply;

  const handleOnOk = async () => {
    if (beforeDeploy && !(await beforeDeploy(deployment))) return;
    const res = await authService.getApolloClient().query({
      query: gql`
        mutation SpecsRevisionController_createRevision($applicationId: Int!, $name: String, $description: String, $applyNow: Boolean) {
          SpecsRevisionController_createRevision(applicationId: $applicationId, name: $name, description: $description, applyNow: $applyNow) {
            id
          }
        }
      `,
      variables: { applicationId: deployment.id, name: revisionName, description: revisionDescription, applyNow: !applyLater },
    });
    setShowModal(false);
  };

  const handleOnChangeInput = (e: iNewRevision) => setRevisionName(e.target.value);
  const handleOnChangeTextArea = (e: iNewRevision) => setRevisionDescription(e.target.value);

  /**
   * @todo: https://nanoheal.atlassian.net/browse/DP-751
   * take deployments with the same `deployment.projectId` and suggest to create a new revision for them too.
   */
  return (
    <>
      <TipLeft tip={hasChangesForApply ? 'Application has changes, create a new revision if you want to apply them' : 'No changes detected'}>
        <Button disabled={disabled || false} type={hasChangesForApply ? 'primary' : 'default'} onClick={async () => setShowModal(true)}>
          {btnText || `Create new revision`}
        </Button>
      </TipLeft>
      <Modal title="Create new revision" open={showModal} onOk={handleOnOk} onCancel={() => setShowModal(false)}>
        <Space direction="vertical" style={spaceWidth}>
          <Text> Please provide revision name and description below: </Text>
          <Input placeholder="Enter Revision name here" value={revisionName} onChange={handleOnChangeInput} />
          <TextArea placeholder="Enter Revision description here" value={revisionDescription} onChange={handleOnChangeTextArea} rows={5} />
        </Space>
      </Modal>
    </>
  );
};

export const SpecsRevisions = ({ deployment }: iSpecsRevisions) => {
  const { lastRevisionId } = deployment;
  const [leftRevisionId, setLeftRevisionId] = useState<number | null>(lastRevisionId || null);
  const [rightRevisionId, setRightRevisionId] = useState<number | null>(lastRevisionId || null);
  const [leftRevisionData, setLeftRevisionData] = useState<Array<{ specs: any; vars: any; services: any }>>([]);
  const [rightRevisionData, setRightRevisionData] = useState<Array<{ specs: any; vars: any; services: any }>>([]);
  const [showLastApplied, setShowLastApplied] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isEditorLoading, setIsEditorLoading] = useState(false);
  const deployID = Number(deployment?.id);
  const leftVer = Number(leftRevisionId);
  const rightVer = Number(rightRevisionId);

  useEffect(() => {
    setLeftRevisionId(lastRevisionId);
  }, [lastRevisionId]);

  const specsRevisions = useAuthedQuery(
    gql`
      query SpecsRevisionController_getList($applicationId: Int!) {
        SpecsRevisionController_getList(applicationId: $applicationId) {
          id
          createdAt
          name
          userId
          description
        }
      }
    `,
    { skip: !deployID, variables: { applicationId: deployID } },
  );

  const { data: leftSpecsRevisionData } = useAuthedQuery(
    gql`
      query SpecsRevisionController_getOne($applicationId: Int!, $leftRevisionId: Int!) {
        SpecsRevisionController_getOne(applicationId: $applicationId, revisionId: $leftRevisionId) {
          id
          createdAt
          name
          userId
          description
          specs
        }
      }
    `,
    { skip: !leftVer, variables: { leftRevisionId: leftVer, applicationId: deployID } },
  );

  const { data: rightSpecsRevisionData } = useAuthedQuery(
    gql`
      query SpecsRevisionController_getOne($applicationId: Int!, $rightRevisionId: Int!) {
        SpecsRevisionController_getOne(applicationId: $applicationId, revisionId: $rightRevisionId) {
          id
          createdAt
          name
          userId
          description
          specs
        }
      }
    `,
    { skip: !rightVer, variables: { rightRevisionId: rightVer, applicationId: deployID } },
  );

  const [SpecsRevisionController_applyRevision] = useAuthedMutation(gql`
    mutation SpecsRevisionController_applyRevision($applicationId: Int!, $leftRevisionId: Int!) {
      SpecsRevisionController_applyRevision(applicationId: $applicationId, leftRevisionId: $leftRevisionId)
    }
  `);

  const { data: revSpec } = specsRevisions;
  const revisions: iSpecsRevision[] = revSpec?.SpecsRevisionController_getList;

  useEffect(() => {
    const updateData = (data: { SpecsRevisionController_getOne: { specs: any } }, prev: { specs: any; vars: any; services: any }[]) =>
      data?.SpecsRevisionController_getOne?.specs ? [data.SpecsRevisionController_getOne.specs] : prev;
    setLeftRevisionData(prev => updateData(leftSpecsRevisionData, prev));
    setRightRevisionData(prev => updateData(rightSpecsRevisionData, prev));
    setIsEditorLoading(false);
  }, [leftSpecsRevisionData, rightSpecsRevisionData]);

  const comparisionData = () => {
    const getSpecsData = (data: { specs: any }[]) => arrToYamlString(data?.[0]?.specs || []);
    return (
      showLastApplied &&
      (lastRevisionId ? (
        <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
          {isEditorLoading ? (
            <Skeleton active loading />
          ) : (
            <DiffEditor height="90vh" language="yaml" original={getSpecsData(leftRevisionData)} modified={getSpecsData(rightRevisionData)} />
          )}
        </FullScreenEditor>
      ) : (
        <Alert showIcon type="warning" key="info" message="Kindly create a revision to compare them in the Editor" />
      ))
    );
  };

  const alertData = () => {
    const alertAction = () => (
      <Space direction="horizontal">
        {showLastApplied && <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />}
        <Button type="primary" onClick={() => setShowLastApplied(!showLastApplied)}>
          {showLastApplied ? 'View timeline' : 'View editor'}
        </Button>
      </Space>
    );
    const alertMessage = `Revision: A snapshot of your application configuration, create a new revision anytime and apply it to test configurations or roll back to a previous one.`;
    return <Alert showIcon type="info" key="info" message={alertMessage} action={alertAction()} />;
  };

  const selectRevision = () => {
    const items =
      revisions?.map(({ id, name }: iSpecsRevision) => {
        const itemID = id.toString();
        const labelData = (
          <Space direction="vertical">
            <Text strong> {name || 'Anonymous Revision'} </Text>
          </Space>
        );
        return { key: itemID, value: itemID, label: labelData, name: name };
      }) || [];

    const editorData = () => {
      const selectorData = () => {
        const handleOnChange = (setRevisionId: isetRevisionId) => value => {
          setRevisionId(Number(value));
          setIsEditorLoading(true);
        };
        return [
          { defaultValue: lastRevisionId.toString(), onChange: handleOnChange(setLeftRevisionId), placeholder: 'Select a revision' },
          {
            defaultValue: lastRevisionId.toString(),
            onChange: handleOnChange(setRightRevisionId),
            placeholder: 'Select a revision to Compare',
            style: revisionWidth,
          },
        ];
      };
      const handleFilters = (input: string, option: { name: string }) => option?.name?.toLowerCase().includes(input.toLowerCase());
      return (
        <Space style={spaceWidth}>
          <Flex style={contentJustified}>
            {selectorData().map((selectProps, index) => (
              <Select key={index} {...selectProps} showSearch filterOption={handleFilters} options={items} suffixIcon={<DownOutlined />} />
            ))}
          </Flex>
        </Space>
      );
    };

    return showLastApplied && lastRevisionId && editorData();
  };

  const timelineData = () => {
    const newRevision = <NewRevisionForm applyLater={true} deployment={deployment} onNewRevision={() => specsRevisions.refetch()} />;

    const timelineContent = () => {
      const itemsData = [
        ...revisions.map(({ id, name, createdAt, description }: iSpecsRevision) => {
          const revisionContent = () => {
            const revisionStatus = () => (
              <Text>
                Revision {name} is created at: {moment(Number(createdAt)).format('DD:MM:YYYY ~ HH:mm:ss A')}
              </Text>
            );
            const revisionButton = () => {
              const popTitle = `Apply revision ${name || 'without name'}?`;
              const popDescription = description || 'No description';
              const handleOnConfirm = () => {
                setLeftRevisionId(id);
                SpecsRevisionController_applyRevision({ variables: { applicationId: deployment.id, leftRevisionId: id } });
              };
              return (
                <Popconfirm title={popTitle} description={popDescription} onConfirm={handleOnConfirm}>
                  <Button disabled={leftRevisionId === id} style={buttonBorder}>
                    {leftRevisionId === id ? 'Applied' : 'Apply'}
                  </Button>
                </Popconfirm>
              );
            };
            return (
              <Flex gap="middle" justify="space-between">
                {revisionStatus()}
                {revisionButton()}
              </Flex>
            );
          };
          return { color: leftRevisionId === id ? 'green' : 'grey', children: revisionContent() };
        }),
        { color: 'blue', children: newRevision },
      ];
      return <Timeline mode="left" reverse={true} items={itemsData} />;
    };

    return (
      !showLastApplied && (
        <>
          <Text /> {revisions?.length ? timelineContent() : <Text> No revisions yet: {newRevision} </Text>}
        </>
      )
    );
  };

  return !revSpec ? (
    <Skeleton active loading />
  ) : (
    <Space direction="vertical" style={spaceWidth}>
      {alertData()}
      {timelineData()}
      {selectRevision()}
      {comparisionData()}
    </Space>
  );
};
