import { Space, Tag, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useApiQuery } from '../../../utils/common';
import { deploymentService } from '../../../services/deployment.service';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;

/**
 * UI element to show the history of updates
 * @param props
 * @returns
 */

export default function InfoUpdate(props: any) {
  const [version, VersionError, VersionLoading] = useApiQuery(() => deploymentService.getInfoUpdateDeployment(props.valueElement));
  console.log('Version: ', version);

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <TipBottom tip="Property name">
          <Tag color="magenta">{props.keyElement}</Tag>
        </TipBottom>
        <ArrowRightOutlined />
        {props.valueElementOld ? (
          <>
            <TipBottom tip="Old value">
              <Tag color="magenta">{props.valueElementOld}</Tag>
            </TipBottom>
            <ArrowRightOutlined />
          </>
        ) : (
          ''
        )}
        <TipBottom tip="New value">
          <Tag color="green">
            {props.valueElement} {version ? ' : ' + version.nameVersion : ''}
          </Tag>
        </TipBottom>
      </Space>
      <Text />
    </Space>
  );
}
