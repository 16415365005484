import './BottomButtons.scss';
import { Space } from 'antd';
import { relatively } from 'utils/styles';

interface iBottomButtonsProps {
  children: any;
  extra?: any;
}

export const BottomButtons = ({ children, extra }: iBottomButtonsProps) => (
  <div className="bottomButtons">
    <Space direction="horizontal" style={relatively}>
      {children}
      <div className="bottomButtonsExtra">
        <Space direction="horizontal"> {extra} </Space>
      </div>
    </Space>
  </div>
);
