import gql from 'graphql-tag';
import CountUp from 'react-countup';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { iRegionModel } from 'shared/deployment';
import { useUser } from 'utils/common';
import { iMyUserData } from 'shared/user';
import { useAuthedQuery } from 'utils/qlAuth';
import { Col, Skeleton, Alert, Statistic, Typography, Button } from 'antd';
import { bottomMargin } from 'utils/styles';
import { TipRight } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;

const formatter = (value: number) => {
  if (value === 0 || value > 3600 * 24 * 365)
    return (
      <Text strong style={{ color: '#FF7777' }}>
        Never synced
      </Text>
    );
  return <CountUp end={value} separator="," />;
};

export interface iClusterOverViewProps {
  region: iRegionModel;
}

export default function ClusterOverView(props: iClusterOverViewProps) {
  const user: iMyUserData = useUser();
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query ApiAgentController_getStatus($regionId: Int!) {
        ApiAgentController_getStatus(regionId: $regionId) {
          status
          message
          noSyncTime
        }
      }
    `,
    { skip: !props?.region?.id, variables: { regionId: props.region.id }, pollInterval: 25000 },
  );
  startPolling(25000);

  useEffect(() => {
    return stopPolling;
  }, []);

  let apiAgentStatus = data?.ApiAgentController_getStatus;

  const region = props.region;
  if (!region || !user || !apiAgentStatus) {
    return <Skeleton active={true} loading={true} />;
  }

  if (region.tenant !== user.tenant) {
    return (
      <Col className="gutter-row " span={24}>
        <Col>
          <Alert type="info" message="This cluster is public. You can not change settings." />
          <br />
        </Col>
      </Col>
    );
  }

  return (
    <Col className="gutter-row d-flex" span={24}>
      <Col>
        <Alert
          showIcon
          message={apiAgentStatus.message}
          description={<Statistic title="Last sync time" value={apiAgentStatus?.noSyncTime} formatter={formatter} />}
          type={apiAgentStatus.status}
          action={
            <TipRight tip="Click here to check the logs">
              <Link to={`/clusters/${region.id}/api-agent-status`}>
                <Button> Check logs </Button>
              </Link>
            </TipRight>
          }
          style={bottomMargin}
        />
      </Col>
    </Col>
  );
}
