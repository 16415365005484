import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, Input, notification, Space, Typography } from 'antd';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { CloudType_AWS, CloudType_Azure, CloudType_GCP, CloudType_k3s, CloudType_other } from './CloudTypes';
import { K8sVersions } from './k8sVersions';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { isClusterNameValid } from 'shared/services';
import { DEFAULT_CLUSTER_VERSION } from 'shared/k8sVersions';

const { Title, Text } = Typography;

export const ProviderUI = ({ provider }) => {
  if (provider === 'other') {
    return <CloudType_other />;
  }
  if (provider === 'gcp') {
    return <CloudType_GCP />;
  }
  if (provider === 'aws') {
    return <CloudType_AWS />;
  }
  if (provider === 'k3s') {
    return <CloudType_k3s />;
  }
  if (provider === 'azure') {
    return <CloudType_Azure />;
  }
};

export const ClusterCreaionV2_connect = (props: { provider: string }) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [k8sVersion, setK8sVersion] = useState(DEFAULT_CLUSTER_VERSION);

  const [createCluster, createClusterResult] = useAuthedMutationWithNotification(gql`
    mutation RegionsController_createCluster($name: String!, $kubernetesVersion: String!, $cloudProvider: String!) {
      RegionsController_createCluster(name: $name, kubernetesVersion: $kubernetesVersion, cloudProvider: $cloudProvider) {
        id
      }
    }
  `);
  const regionId = createClusterResult?.data?.RegionsController_createCluster?.id;
  if (regionId) {
    console.log('regionId', regionId);
    history.push(`/cluster/install/${regionId}/agent`);
  }

  const onSave = async () => {
    console.log('Save');

    if (!name) {
      notification.error({ message: `Cluster name is required` });
      return;
    }
    if (!isClusterNameValid(name)) {
      notification.error({ message: `Cluster name is invalid` });
      return;
    }

    const clusterData = {
      name: name,
      kubernetesVersion: k8sVersion,
      cloudProvider: String(props.provider),
    };
    createCluster({
      variables: clusterData,
    });
  };

  return (
    <Space direction="vertical" style={spaceWidth} data-qa={`ClusterCreaionV2_connect`}>
      <TitleUIRow title={`Configure new cluster`} />
      <Text strong> Cluster name </Text>
      The name of the cluster. It will be used in the UI and in the API.
      <Input
        placeholder="Cluster name"
        value={name}
        type="string"
        onChange={e => {
          setName(e.target.value);
        }}
      />
      <Text strong> Kubernate version: </Text>
      <K8sVersions defaultValue={k8sVersion} onChange={setK8sVersion} />
      <ProviderUI provider={props.provider} />
      <BottomButtons>
        <Space>
          <Button disabled={createClusterResult.loading} type="primary" data-qa="ClusterCreaionV2" onClick={onSave} style={buttonBorder}>
            Save & Continue
          </Button>
          <Button type="default" onClick={() => history.push('/first-step')}>
            Back
          </Button>
        </Space>
      </BottomButtons>
    </Space>
  );
};
