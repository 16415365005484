import { ArrowsAltOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Card, Flex } from 'antd';
import React, { useState } from 'react';
import { buttonColor, flexDisplay, floatingRight, spaceHeight, spaceWidth } from 'utils/styles';
import { createContext } from 'react';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface iDashboardItem {
  children: React.ReactNode;
  title: string;
  needFullScreenView?: boolean;
}
export const FullScreenContext = createContext({
  showModal: false,
  setShowModal: (showModal: boolean) => {},
});

const DashboardItem = ({ children, title, needFullScreenView = true }) => {
  const [showModal, setShowModal] = useState(false);

  const FullScreenIcon = () => {
    if (!needFullScreenView) {
      return null;
    }
    return (
      <TipLeft tip="Click here for Full Screen View">
        <Button icon={<FullscreenOutlined style={buttonColor} />} onClick={() => setShowModal(true)} />
      </TipLeft>
    );
  };
  return (
    <FullScreenContext.Provider value={{ showModal, setShowModal }}>
      <Card
        title={title}
        extra={<FullScreenIcon />}
        style={{ ...spaceHeight, ...spaceWidth, ...flexDisplay, flexDirection: 'column' }}
        bodyStyle={spaceHeight}
      >
        {children}
      </Card>
    </FullScreenContext.Provider>
  );
};

export default DashboardItem;
