import { Link } from 'react-router-dom';
import { Card, Typography, Space } from 'antd';
import { ResourcesStates } from '../../Deployments/ResourcesStates/ResourcesStates';
import { spaceWidth } from 'utils/styles';

interface ResourcesCardProps {
  deploymentId: number;
}

const { Text } = Typography;

export const ResourcesCard: React.FC<ResourcesCardProps> = ({ deploymentId }) => {
  return (
    <>
      <Card title="Resources" bordered={false}>
        <Space direction="vertical" style={spaceWidth}>
          <ResourcesStates key="ResourcesStates" deploymentId={deploymentId} />
          <Text />
          <Text strong>
            <Link to={`/app/${deploymentId}/logs/pods`}> View cloud monitoring dashboard </Link>
          </Text>
        </Space>
      </Card>
    </>
  );
};
