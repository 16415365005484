import history from '../history';
import { useState, useEffect } from 'react';
import { authService } from '../services/auth.service';
import { TenantPermissions } from 'shared/permissions';
import { iMyUserData } from 'shared/user';

interface TenantInfo {
  name: string;
  id: number;
  useKeyCloak: boolean;
  roles: string[];
}

export const useTenantInfo = (): TenantInfo => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const checkData = async () => {
      const res = await authService.getTenantInfo();
      if (!res?.error) setData(res);
    };
    checkData();
  }, []);
  return data;
};

export const useTenantPermissions = (): TenantPermissions[] => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const checkData = async () => {
      const res = await authService.getTenantPermissionsInfo();
      if (!res?.error) setData(res);
    };
    checkData();
  }, []);
  return data;
};

export const goToLoginLink = () => {
  const oldPath = window.location.href.replace(window.location.origin, '');
  return '/login/r/' + encodeURIComponent(oldPath);
};

export const goToLogin = () => {
  if (window.location.href.indexOf(`/login/r/`) !== -1) {
    return;
  }

  const oldPath = window.location.href.replace(window.location.origin, '');
  history.push('/login/r/' + encodeURIComponent(oldPath));
};

export const useUser = (): iMyUserData => {
  const udata = authService.getSelfInfoNow();
  const [user, setUser] = useState<iMyUserData>(udata);

  useEffect(() => {
    if (!udata) {
      const checkUer = async () => {
        const res = await authService.getSelfInfo();
        if (res.error) {
          goToLogin();
          return;
        }
        setUser({
          allowApi: res.data.allowApi,
          antThemeName: res.data.antThemeName,
          antThemeToken: res.data.antThemeToken,
          avatar: res.data.avatar,
          email: res.data.email,
          githubLogin: res.data.githubLogin,
          id: res.data.id,
          name: res.data.name,
          roles: res.data.roles,
          tenant: res.data.tenant,
          isConfirmed: true,
          createdAt: null,
          updatedAt: null,
        });
      };
      checkUer();
    }
  }, []);
  return user;
};

export const useCubeJsApi = (deps = []) => {
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkUer = async () => {
      setLoading(true);
      const res = await authService.getSelfInfo();
      if (res.error) {
        setError(res.error);
        setLoading(false);
        return;
      }
      setRes(res.cubejsClient);
      setLoading(false);
    };
    checkUer();
  }, deps);
  return [res, error, loading];
};

export const handleInputChange = (f: any) => {
  return (e: any) => {
    return f(e.target.value);
  };
};

/**
 * @param apiCallBack
 * @param deps agrs for useEffect function
 * @returns
 */

export const useApiQuery = (apiCallBack: any, deps = []) => {
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiCall = async () => {
    setLoading(true);
    try {
      const res = await apiCallBack();
      if (res.error) {
        setError(res.error);
        setLoading(false);
        return;
      }
      setRes(res.data);
      setLoading(false);
    } catch (error) {
      console.log(`useApiQuery error:`, error);
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    apiCall();
  }, deps);
  return [res, error, loading, apiCall];
};

export interface iUseDpApi<DataType> {
  answer: DataType;
  error: any;
  loading: boolean;
}

/**
 * @param apiCallBack
 * @param deps agrs for useEffect function
 * @returns
 */

export const useDpApi = (apiCallBack: Function, deps = []): iUseDpApi<any> => {
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiCall = async () => {
    setLoading(true);
    try {
      const res: iBaseAjaxAnswer<any> = await apiCallBack();
      if (res.error) {
        setError(res.error);
        setLoading(false);
        return;
      }
      setRes(res.data);
      setLoading(false);
    } catch (error) {
      console.log(`useApiQuery error:`, error);
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    apiCall();
  }, deps);
  return { answer: res, error, loading };
};

export const useApiQueryLoop = (apiCallBack: any, interval = 5000) => {
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  let stopUpdates = false;
  const reCall = () => {
    setTimeout(async () => {
      if (stopUpdates) return;
      const res = await apiCallBack();
      if (stopUpdates) return;
      if (res.error) {
        setError(res.error);
        return;
      }
      setRes(res.data);
      reCall();
    }, interval);
  };

  useEffect(() => {
    const apiCall = async () => {
      const res = await apiCallBack();
      if (res.error) setError(res.error);
      else setRes(res.data);
      setLoading(false);
      reCall();
    };
    apiCall();
    return () => {
      stopUpdates = true;
    };
  }, []);
  return [res, error, loading];
};

export interface iBaseAjaxAnswer<DataType> {
  data: DataType;
  status: number;
  error: boolean;
}

export async function apiQuery(method: any, url: any, body: any = null): Promise<iBaseAjaxAnswer<any>> {
  const opt = { redirect: 'follow', method, credentials: 'include', headers: { 'Content-Type': 'application/json' } };

  if (body) {
    // @ts-ignore
    opt.body = JSON.stringify(body);
  }

  // @ts-ignore
  const res = await fetch(url, opt);
  const data = await res.json();
  return { data: data, status: res.status, error: !(res.status >= 200 && res.status < 300) };
}

function pluralize(value, singular, plural) {
  return value === 1 ? `${value} ${singular}` : `${value} ${plural}`;
}

export const uptimeToString = (seconds: number) => {
  const dayMath = Math.floor(seconds / 1000 / 3600 / 24);
  const hourMath = Math.floor((seconds / 1000 / 3600) % 24);
  const minuteMath = Math.floor((seconds / 1000 / 60) % 60);
  const secondMath = Math.floor((seconds / 1000) % 60);
  const uptimeData =
    dayMath > 0
      ? pluralize(dayMath, 'day', 'days')
      : hourMath > 0
      ? pluralize(hourMath, 'hour', 'hours')
      : minuteMath > 0
      ? pluralize(minuteMath, 'minute', 'minutes')
      : pluralize(secondMath, 'second', 'seconds');
  return uptimeData;
};

/**
 * if window.location.host have only one dot symbol then this main site like nanoheal.site
 * if window.location.host has 2 dot symbols then this organization site like tesla.nanoheal.site
 * @returns
 */

const appDomainPrimary = [`auth.unifie.work`, `auth.unifie.cloud`, `auth.nanoheal.work`, `auth.nanoheal.site`, `nanoheal.work`, `nanoheal.site`];
const appDomainSecondary = [`unifie.work`, `unifie.cloud`];
const hosts = window.location.host;
const domainReturn = hosts.replace(/:.*$/, '') != 'localhost' && hosts.split('.').length <= 2;

export function isMainDomain() {
  return appDomainPrimary.includes(hosts) ? true : domainReturn;
}

export function getMainDomainUrl() {
  const host = window.location.host.split('.');
  if (host.length === 1) {
    // localhost or some other local domain (without dot)
    return `auth.${window.location.host}`;
  } else if (host.length === 2) {
    // unifie.cloud or nanoheal.site
    return `auth.${window.location.host}`;
  }
  host[0] = 'auth';
  return host.join('.');
}

export function isLandingDomain() {
  return appDomainSecondary.includes(hosts) ? true : domainReturn;
}

export const crudAdminFiltersApi = () => {
  const options = {};
  window.location.hash
    .split(/[?&;]/)
    .filter(v => v.indexOf('=') != -1)
    .filter(v => v.indexOf('f_') == 0)
    .map(v => {
      const res = v.replace('f_', '').split('=');
      options[res[0]] = res[1];
      return res;
    });
  return options;
};

export function crudAdminBuildParams(defaultFilters, getFilters, params: any, sort, filter) {
  if (Object.keys(getFilters).length == 0) {
    Object.keys(defaultFilters).map(key => {
      params[key] = defaultFilters[key];
    });
  }
  Object.keys(getFilters).map(key => {
    params[key] = getFilters[key];
  });
  Object.keys(filter).map(key => {
    params[key] = filter[key];
  });
  if (Object.keys(sort)[0]) {
    params.sortKey = Object.keys(sort)[0] || 'id';
    params.sortType = sort[params.sortKey].replace('end', '');
  }
  return params;
}

export function makeId(length = 6): string {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result;
}

export function getUrlHashParams(): { [key: string]: string } {
  const hash = window.location.hash;
  const hashParams = hash.split('?')[1];
  if (!hashParams) {
    return {};
  }
  return hashParams.split('&').reduce((acc, item) => {
    const [key, value] = item.split('=');
    acc[key] = value;
    return acc;
  }, {});
}

export function capitalizeFirstLetter(string: string): string {
  return (
    String(string || '')
      .charAt(0)
      .toUpperCase() + String(string || '').slice(1)
  );
}
